import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import { useParams } from "react-router-dom";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import Countdown from 'react-countdown'


const Web3 = require('web3'); 
const web3 = new Web3(process.env.REACT_APP_RPC); 
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
function Dashboard(){
    const [stakeData,setStakeDate] = useState({});
    const [isStaked,setIsStaked] = useState(true);
    const [nextUtc,setNextUtc]=useState()
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber();
    let history = useHistory();
    
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
            return "";
            } else {
          // Render a countdown
          return <span>{days} DAYS {hours} HOURS {minutes} MINUTES {seconds} SECONDS</span>;
            }
        };
    
    let params = useParams();
    
    let other_id = params.id;
    
    useEffect(() => {
        getTokenData();
      }, []);
    async function getTokenData()
    {
        const tmrw = new Date()
        tmrw.setDate(tmrw.getDate()+1)
        
        tmrw.setUTCHours(0,0,0,0)
        console.log(new Date(new Date(tmrw).toUTCString()))
        setNextUtc(new Date(new Date(tmrw).toUTCString()))
    const StakeFetch = await fetch(process.env.REACT_APP_LEDGER_API+"v1/summary/getToken/"+other_id);
      const StakeRow = await StakeFetch.json();
      const stakeRes= StakeRow[0]
      if(!stakeRes){
        setIsStaked(false)
      } else { 
      var stakeT = {}
      stakeT['claimed'] = 0
      stakeT['grade'] = 0
      stakeT['grade_sum'] = 0
      stakeT['in_blocks'] = 0
      stakeT['index_sum'] = 0
      stakeT['last_earn'] = 0
      stakeT['last_index'] = 0
      stakeT['life_earn'] = 0
      stakeT['rank'] = 0
      stakeT['rank_sum'] = 0
      stakeT['regDate'] = " "
      stakeT['sevDay'] = []
      stakeT['string'] = ""
      stakeT['unminted'] = 0
      stakeT['updated'] = ""
      stakeT['yld'] = 0
      const add = function(arr) {
        return arr.reduce((a, b) => a + b, 0);
    };
      if(stakeRes.hasOwnProperty('claimed')){
        stakeT['claimed'] = (stakeRes['claimed']).toFixed(4)
      }
      if(stakeRes.hasOwnProperty('grade')){
        stakeT['grade'] = (stakeRes['grade'])
      }

      if(stakeRes.hasOwnProperty('grade_sum')){
        stakeT['grade_sum'] = (stakeRes['grade_sum'])
      }

      if(stakeRes.hasOwnProperty('in_blocks')){
        stakeT['in_blocks'] = (stakeRes['in_blocks'])
      }

      if(stakeRes.hasOwnProperty('index_sum')){
        stakeT['index_sum'] = (stakeRes['index_sum'])
      }

      if(stakeRes.hasOwnProperty('last_earn')){
        stakeT['last_earn'] = (stakeRes['last_earn']).toFixed(4)
        stakeT['yld'] = (stakeRes['last_earn']*24*365).toFixed(4)
      }

      if(stakeRes.hasOwnProperty('last_index')){
        stakeT['last_index'] = (stakeRes['last_index'])
      }

      if(stakeRes.hasOwnProperty('life_earn')){
        stakeT['life_earn'] = (stakeRes['life_earn']).toFixed(4)
      }

      if(stakeRes.hasOwnProperty('rank')){
        stakeT['rank'] = (stakeRes['rank'])
      }

      if(stakeRes.hasOwnProperty('rank_sum')){
        stakeT['rank_sum'] = (stakeRes['rank_sum'])
      }

      if(stakeRes.hasOwnProperty('regDate')){
        stakeT['regDate'] = (stakeRes['regDate']).split("T")[0]
      }
      
      if(stakeRes.hasOwnProperty('sevDay')){
        stakeT['sevDay'] = (add(stakeRes['sevDay'])).toFixed(4)
      }

      if(stakeRes.hasOwnProperty('string')){
        stakeT['string'] = (stakeRes['string'])
      }

      if(stakeRes.hasOwnProperty('unminted')){
        stakeT['unminted'] = (stakeRes['unminted']).toFixed(4)
      }

      if(stakeRes.hasOwnProperty('updated')){
        stakeT['updated'] = (stakeRes['updated'])
      }

      setStakeDate(stakeT)
    }
    }
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
       {isStaked?(<>
		<h3>Hashtag Information</h3>
		<div class="myhashtags">
			<ul>
				<li><span>#{stakeData.string}<label>NFT ID : {other_id}</label></span></li>
			</ul>
		</div>
        <div class="performancedata">
			<h4 class="text-center">Performance Data</h4>
			<h6 class="text-center"><a href="/Grades" class="btn">VIEW GRADE PAYOUT CHART</a></h6>
			<ul class="accinfo-list">
				<li><label>REGISTERED ON</label><span>{(stakeData.regDate)}</span></li>
				<li><label>CURRENT GRADE</label><span>{(stakeData.grade)}</span></li>
				<li><label>AVERAGE GRADE</label><span>{((stakeData.grade_sum/stakeData.in_blocks).toFixed(0))}</span></li>
				<li><label>CURRENT RANK</label><span>{(stakeData.rank)}</span></li>
				<li><label>AVERAGE RANK</label><span>{((stakeData.rank_sum/stakeData.in_blocks).toFixed(0))}</span></li>
				<li><label>tag mined (PAST HOUR)</label><span>{(stakeData.last_earn)}</span></li>
				<li><label>tag mined (PAST 7 DAYS)</label><span>{(stakeData.sevDay)}</span></li>
				<li><label>tag mined (ALL TIME)</label><span>{(stakeData.life_earn)}</span></li>
				<li><label>ESTIMATED YEARLY YIELD</label><span>{(stakeData.yld)}</span></li>
			</ul>
            <span class="per_utc_hr">Last updated : {stakeData.updated} ( UTC )</span>
		</div>
		<div class="app-footer bg-white pt-0">
			<h4 class="mb-0" style={{display:"none"}}>TAGCOIN Balance</h4>
			<div class="balancesec" style={{display:"none"}}>
				<span style={{color:"#1e82ce"}}>0.0000 TAG</span> |
				<span style={{color:"#d49816"}}>0.0000 USD</span>
			</div>
		</div>
        </>):
        (<>
        <div style={{textAlign:"center",paddingTop:"20vh", paddingBottom:"20vh"}}><strong>Hashtag Awaiting Staking</strong><p><br/>Newly registered hashtags are not staked until midnight UTC</p><p>Please be patient.</p></div>
        </>)}
		<div class="app-btnsec">
			<a onClick={() => history.goBack()} class="btn mr-2">Back</a>
		</div>
	</div>
      
    
     

   

    </>
    );
}
export default Dashboard;