import React, { useRef, useState, useEffect } from "react";
import BackButton from "../components/sharedBackButton";
import Countdown from "react-countdown";
import "../newStyle.css";
import { io } from "socket.io-client";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import tagminer from "../images/tagminer.png";
import tagtrader from "../images/tagtrader.png";
import liqprovider from "../images/liqprovider.png";
import liqfarmer from "../images/liqfarmer.png";
import htagtrader from "../images/htagtrader.png";
import htaggrader from "../images/htaggrader.png";
import popup from "../images/popup/lbank-listing.jpeg";

const summaryUrl = process.env.REACT_APP_TREASURY_API + "v1/summary/";
function MainLanding() {
  const Completed = () => <span>THE SALE OF 100,000 HASHTAGS</span>;
  const [summaryData, setSummaryData] = useState({});
  const [recentData, setRecentData] = useState([]);
  const [recentData1, setRecentData1] = useState("0.0000");
  const [recentData2, setRecentData2] = useState(0);
  const [pchange, setPchange] = useState("none");
  const [disable, setDisable] = useState(false);
  document.body.classList.remove("bluebg");
  document.body.classList.remove("app-body");
  document.body.classList.add("patternbody");
  document.body.classList.add("app-body");
  // Renderer callback with condition
  const [resan, setResan] = useState([]);
  const refHolder = useRef(null);
  const [curpage, setCurpage] = useState();
  const refPurchase = useRef([]);
  const validateUrl =
    process.env.REACT_APP_TREASURY_API + "v1/summary/availability/";

  // const [open, setOpen] = useState(true);//modal trigger

  // const onCloseModal = () => setOpen(false); //close info-popup modal

  var ENGLISH = {};
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    .split("")
    .forEach(function (ch) {
      ENGLISH[ch] = true;
    });
  function stringIsEnglish(str) {
    var index;

    for (index = str.length - 1; index >= 0; --index) {
      if (!ENGLISH[str.substring(index, index + 1)]) {
        return false;
      }
    }
    return true;
  }
  function handleSubmit(event) {
    event.preventDefault();
  }

  async function paginate(page = 0) {
    console.log("Page is " + page);
    setCurpage(page);

    const recents =
      process.env.REACT_APP_TREASURY_API + "v1/summary/recents/4/" + page;
    const recentList = await fetch(recents);
    const recentListRows = await recentList.json();
    const recentListDis = await recentListRows.map(function (k, i) {
      return (
        <li class="" key={i}>
          #{k.hashtag}
        </li>
      );
    });
    setRecentData(recentListDis);
  }
  const [research, setResearch] = useState([]);
  const [variants, setVariants] = useState([]);
  const [kwData, setKwData] = useState("");
  const [disData, setDisData] = useState();
  const [errData, setErrData] = useState("");
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <span>
        {days} DAYS {hours} HOURS {minutes} MINUTES {seconds} SECONDS
      </span>
    );
  };

  async function getMarketData() {
    var LTP = await fetch(
      "https://tphhph41dd.execute-api.us-east-1.amazonaws.com/prod/getLatestTrades/1"
    );
    var market24 = await fetch(
      "https://tphhph41dd.execute-api.us-east-1.amazonaws.com/prod/get24MarketStat"
    );

    LTP = await LTP.json();
    market24 = await market24.json();

    const LTprice = LTP[0]["TAG_USD"];
    const LTpriceBNB = LTP[0]["TAG_BNB"];
    const open = market24[0]["open_usd"].toFixed(4);
    const openBNB = market24[0]["open_bnb"];
    const priceChange = ((LTprice - open) / LTprice) * 100;

    setRecentData1(LTprice.toFixed(4) + " USD");
    setRecentData2(priceChange.toFixed(2));

    if (priceChange > 0) {
      setPchange("green");
    } else {
      setPchange("red");
    }
  }
  useEffect(() => {
    getMarketData();
    const interval = setInterval(() => {
      getMarketData();
    }, 5000);
    getSummary();
  }, []);

  const getSummary = async () => {
    const response = await fetch(summaryUrl);
    const jsonData = await response.json();
    setSummaryData(jsonData);
    console.log(jsonData);
    paginate(0);
  };
  async function checkRef(e = null) {
    var refAddress = null;
    setDisable(true);

    console.log(e);
    if (typeof e === "string") {
      refAddress = e;
    } else {
      if (e && e instanceof Event) {
        console.log(e);
        refAddress = e;
      } else {
        const form = refHolder.current;
        refAddress = `${form["hsearch"].value}`;
      }
    }
    refHolder.current["hsearch"].value = refAddress;
    refAddress = refAddress.replace(/[^0-9a-z]/gi, "");
    refAddress = refAddress.replace(/ /g, "");
    const response = await fetch(validateUrl + refAddress);
    const jsonData = await response.json();
    setDisData(1);
    setKwData(refAddress);
    if (jsonData.error) {
      setErrData("Available");
      console.log("Available, trigger research mode");
    } else setErrData("Not available");
    const researchEp =
      process.env.REACT_APP_TREASURY_API + "v1/summary/research/" + refAddress;
    const rdfx = await fetch(researchEp);
    const researchData = await rdfx.json();
    console.log(researchData);
    var error = false;
    if (researchData.hasOwnProperty("error")) {
      error = true;
    }
    if (!error) {
      setResan(researchData);
      try {
        const recentListDis = await researchData["related"].map(function (
          k,
          i
        ) {
          if (stringIsEnglish(k.hashtag))
            return (
              <li class="" onClick={() => checkRef(k.hashtag)} key={i}>
                #{k.hashtag}
              </li>
            );
        });

        const variantListDis = await researchData["variants"].map(function (
          k,
          i
        ) {
          if (stringIsEnglish(k.hashtag))
            return (
              <li onClick={() => checkRef(k.hashtag)} key={i}>
                #{k.hashtag}
                <span>{Math.floor(k.perusage)} %</span>
              </li>
            );
        });
        setResearch(recentListDis);
        setVariants(variantListDis);
      } catch (e) {
        setResearch(null);
        console.log(e);
      }
    } else {
      setResearch([]);
      setVariants([]);
    }
  }
  return (
    <>
      <div class="header text-center">
        <a class="logo" href="/">
          <img
            src="https://tagprotocol.s3.us-east-1.amazonaws.com/logo-white-sb.png"
            class="img-fluid"
            style={{ height: "40px" }}
          />
        </a>
      </div>

      <div class="app-content hashapp-content">
        <div id="tagcoinWidget">
          <div class="TagcoinPriceLanding">
            <span style={{ color: "#207bca" }}>
              <strong>TAGCOIN</strong>
            </span>{" "}
            : <strong>{recentData1}</strong>
            <small class={pchange}>{recentData2} %</small>
          </div>
          <div class="tradeCTA">
            <a href="https://tagcoin.io" target="_blank">
              <img src="https://tagcoin.io/static/media/favicon.ad65ef45.png" />
              Trade on Tagcoin.io
            </a>
          </div>
        </div>

        {disable == true ? <h2>REGISTER Hashtag</h2> : <></>}

        <form class="reghashtag" ref={refHolder} onSubmit={handleSubmit}>
          <div
            class="form-group d-flex searchsec"
            style={{ background: "#265974" }}
          >
            <input
              type="text"
              class="form-control"
              style={{ borderRadius: "0px" }}
              id=""
              name="hsearch"
              placeholder="Enter your hashtag..."
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  checkRef();
                }
              }}
            />
            <input
              type="button"
              class="form-control"
              onClick={checkRef}
              value="CHECK AVAILABILITY"
            />
          </div>
        </form>
        {disable == false ? (
          <>
            <h3 className="varih3" style={{ color: "#1f7ccb" }}>
              REGISTER <label style={{ color: "#5eb9ff" }}>#hashtag</label> NFT
              and USE IT <br />
              AS A VIRTUAl MINING MACHINE TO MINE{" "}
              <label style={{ color: "#5eb9ff" }}>TAGCOIN</label>.
            </h3>
            <div class="btnsect">
              <a href="/wallet" class="btn btnblue sltvwidth">
                Open App
              </a>
              <a
                href="https://tagscan.info/"
                target="_blank"
                class="btn btnlightblue sltvwidth"
              >
                Tagscan
              </a>
              <a
                href="https://tagprotocol.gitbook.io/tagprotocol/"
                class="btn btnblue sltvwidth"
                id="btnlightbluev2"
                target="_blank"
              >
                Learn More
              </a>
              <a
                href="https://tagprotocol.s3.us-east-1.amazonaws.com/Tagcoin%20-%20White%20Paper.pdf"
                class="btn btnlightblue sltvwidth"
                id="btnbluev2"
                target="_blank"
              >
                Whitepaper
              </a>
            </div>
            <br />
            <br />
            <div class="stakinglaunch">
              <label
                style={{
                  "font-size": "16px",
                  "font-weight": "800",
                  color: "#1f7ccb",
                }}
              >
                Tagcoin Halving In -
              </label>
              <Countdown
                date={new Date(Date.parse("08/21/2023 04:00:00 GMT+4"))}
                renderer={renderer}
              />
            </div>
            <br />
            <br />
            <h3 style={{ color: "#1f7ccb" }}>PARTICIPATE AS</h3>
            <div className="flex-container">
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={tagminer}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a href="/wallet" class="btn conbutblue col-6">
                    START NOW
                  </a>
                  <a
                    href="https://tag-help.com/how-to-become-a-tagcoin-miner/"
                    target="_blank"
                    class="btn conbutyellow col-6"
                  >
                    HOW TO
                  </a>
                </div>
              </div>
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={tagtrader}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a
                    href="https://tagcoin.io/"
                    target="_blank"
                    class="btn conbutblue col-6"
                  >
                    START NOW
                  </a>
                  <a
                    href="https://tag-help.com/how-to-become-a-tagcoin-trader/"
                    target="_blank"
                    class="btn conbutyellow col-6"
                  >
                    HOW TO
                  </a>
                </div>
              </div>
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={liqprovider}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a
                    href="https://tagcoin.io/liquidity"
                    target="_blank"
                    class="btn conbutblue col-6"
                  >
                    START NOW
                  </a>
                  <a
                    href="https://tag-help.com/how-to-become-a-liquidity-provider/"
                    target="_blank"
                    class="btn conbutyellow col-6"
                  >
                    HOW TO
                  </a>
                </div>
              </div>
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={liqfarmer}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a
                    href="https://tagcoin.io/lpfarm"
                    target="_blank"
                    class="btn conbutblue col-6"
                  >
                    START NOW
                  </a>
                  <a
                    href="https://tag-help.com/how-to-become-a-liquidity-farmer/"
                    target="_blank"
                    class="btn conbutyellow col-6"
                  >
                    HOW TO
                  </a>
                </div>
              </div>
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={htagtrader}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a href="/" class="btn disable conbutblue col-6">
                    START NOW
                  </a>
                  <a href="/" class="btn disable conbutyellow col-6">
                    HOW TO
                  </a>
                </div>
              </div>
              <div className="col-md-2 sltvpad">
                <img
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={htaggrader}
                  alt=""
                />
                <div style={{ display: "flex", marginTop: "14px" }}>
                  <a href="/" class="btn disable conbutblue col-6">
                    START NOW
                  </a>
                  <a href="/" class="btn disable conbutyellow col-6">
                    HOW TO
                  </a>
                </div>
              </div>
            </div>
            <br />
            {/* <p class="mt-4 mb-4">Utilizing blockchain technology to build a store of value fungible cryptographic asset named as Tagcoin which has limited and defined supply that can be mined by staking another non-fungible cryptographic asset called #hashtags which can be registered and acquired as a NFT token. Tagcoin Mining yield on the #hashtag NFT miner is derived from measuring #hashtag’s performance on various social media platforms using a transparent #hashtag Performance Grading System.</p>
		
		<div class="stakinglaunch">
			<label>Hashtag Marketplace - Launches after</label>
			<Countdown
    date={new Date(Date.parse("12/15/2020 20:00:00 GMT+4"))}
    renderer={renderer}
  />
		</div> */}
            <a
              href="https://t.me/tagprotocol_com"
              class="jcomm"
              target="_blank"
            >
              join our telegram community{" "}
              <img src="/app/app/images/tgicon.png" class="" />
            </a>
            <br />
            <div class="partnersect">
              <div>
                <label>Hosted On</label>{" "}
                <img src="/app/app/images/binancelogo.png" class="" />
              </div>
              <div>
                <label>Written In</label>{" "}
                <img src="/app/app/images/soliditylogo.png" class="" />
              </div>
              <div>
                <label>Non Fungible Token</label>{" "}
                <img src="/app/app/images/nftlogo.png" class="" />
              </div>
              <div>
                <label>Price Oracle</label>{" "}
                <img src="/app/app/images/chainlinklogo.png" class="" />
              </div>
              <div>
                <label>Data Repository</label>{" "}
                <img src="/app/app/images/graphlogo.png" class="" />
              </div>
              <div>
                <label>Mining Based On</label>{" "}
                <img src="/app/app/images/smlogo.png" class="" />
              </div>
            </div>
            <div class="walletregistgered">
              <div>
                <label>UNIQUE WALLETS: </label>
                <span>{summaryData.wallets - 1}</span>
              </div>
              <div>
                <label>HTAG REGISTERED:</label>
                <span>{summaryData.hashtags}</span>
              </div>
            </div>
            <div
              class="disclaimerHome"
              style={{
                marginTop: "20px",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <p
                style={{
                  color: "#1f7ccb",
                  fontWeight: "bold",
                  textTransform: "lowercase",
                }}
              >
                <a href="mailto:info@tagprotocol.com">info@tagprotocol.com</a>
              </p>
              <p style={{ color: "#1f7ccb", fontSize: "10px" }}>
                Crypto project participation is risky & project does not
                guarantee any financial returns
              </p>
            </div>
          </>
        ) : (
          <>
            {errData === "Available" ? (
              <>
                <h3>
                  congratulations! <br />
                  your HASHTAG{" "}
                  <label style={{ color: "#5eb9ff", textTransform: "initial" }}>
                    #{kwData}
                  </label>{" "}
                  is <label style={{ color: "#16c412" }}>available</label>.
                </h3>
                <a href="/wallet" class="btn btnblue" style={{ color: "#fff" }}>
                  Register Now
                </a>
              </>
            ) : (
              <>
                <h3>
                  Sorry! <br />
                  your HASHTAG{" "}
                  <label style={{ color: "#5eb9ff", textTransform: "initial" }}>
                    #{kwData}
                  </label>{" "}
                  is <label style={{ color: "#d60d31" }}>not available</label>.
                </h3>
              </>
            )}
            <div class="popscore">
              {resan.popularity > 0 && (
                <div class="alltimescore">
                  <span>POPULARITY SCORE </span>
                  <label> {resan.popularity}%</label>
                </div>
              )}
            </div>
            <div class="popscore recregistered">
              <h3 class="mt-4 mb-3">Recently Registered</h3>
              <ul class="hashlist">
                {recentData}
                <li style={{ background: "none", border: 0 }}>
                  <a class="viewMoreRec" href="/recentHashtags">
                    View More
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>

      {/* <Modal open={open} onClose={onCloseModal} center>
      <img src={popup} style={{width:"100%"}}/>
      <p className="pstyle" style={{textAlign:"center",marginTop:"10px",fontSize:"0.8rem",padding:"10px"}}>You can open your account with <a href="https://twitter.com/LBank_Exchange" target="_blank">@LBank_Exchange</a> and slowly start to deposit your tagcoins. <br/>
      <br className="showm"/>
        Get ready for trading $TAG / $USDT<br/>
        💡Listing details <a href="https://support.lbank.site/hc/en-gb/articles/6646546775833"  target="_blank">bit.ly/3MliCX8</a></p>
      </Modal> */}
    </>
  );
}
export default MainLanding;
