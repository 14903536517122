import React,{ useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from './sharedBackButton';
import AppFooter from "./Appfooter";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoginCheck from "./LoginCheck"; //<LoginCheck/>
import ls from 'local-storage';
import Loader from "./Loader";
import TreeComponent from "./Tree";

function Reftree(){
    document.getElementsByClassName('connStatus')[0].style.visibility = 'hidden';
    document.getElementsByClassName('connStatus')[0].style.height = '0';
    document.getElementsByClassName('connStatus')[0].style.padding = '0';
    document.getElementsByClassName('connStatus')[0].style.margin = '0';
   useEffect(() => {
      
    }, []);
    const [recentData, setRecentData] = useState([]);
    const [load, setLoad] = useState(0);
    
    return(
        <>
     
   
   <LoginCheck/>

   <BackButton/>
<h4 style={{textAlign:"center","color":"#fff",fontSize:"18px",marginBottom:"15px"}}>Tree View</h4>
   <div class="app-btnsec" style={{paddingTop:0}}>
			<a href="/trackRef" class="btn">Back</a>
	</div>
   
   <TreeComponent data={ls.get('acct')}>
      </TreeComponent>
   
            
      
      
    
   
   

    </>
    );
}
export default Reftree;