import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useWallet } from "use-wallet";
import BackButton from '../components/sharedBackButton';
import ls from 'local-storage';
import { useParams } from "react-router-dom";
import Loader from '../components/Loader';

const validateUrl = process.env.REACT_APP_TREASURY_API+"v1/summary/validate/";
function Exref(){
    const refHolder = useRef(null);
    const [errData, setErrData ] = useState('');
    let history = useHistory();
    const wallet = useWallet()
    let params = useParams();
    let ref_id = params.id;
    var sendTo = "/welcome";
    if(params.type){
        sendTo = "/welcome";
    } 
    if(ref_id){
        ls.set('exref',ref_id);
    }
    else{
        ls.set('exref',"");
    }
    history.push(sendTo);
    return(
        <>
   
   <div class="container1 m-0 p-0 "> 
  <Loader loadingText="Verifying"/>
      
    </div>   


    </>
    );
}
export default Exref;