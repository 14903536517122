import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory, useLocation } from "react-router-dom";
import BackButton from "../components/sharedBackButton";
import AppFooter from "../components/Appfooter";
import Loader from "../components/Loader";
import { store } from "react-notifications-component";
import ls from "local-storage";
import LoginCheck from "./LoginCheck";

var TreasuryABI_JSON = require("./treasuryAbi.json");
var TreasuryABI = TreasuryABI_JSON;
const erc20Abi = require("./tokenABI.json");
//ls.set('acct','0x54cE8D3b33159E15259345e97C27f388ab8B508b');
const Web3 = require("web3");
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Account() {
  const wallet = useWallet();
  //console.log(wallet.ethereum);

  const web3 = new Web3(wallet.ethereum);
  let query = useQuery();
  const walletId = ls.get("acct");
  //ls.set('acct',"0x601f5b4F481790377d24AB4e88dF6889C3C236d7")
  const CONTRACT_ADDRESS = process.env.REACT_APP_TREASURY_ADDR;
  const CONTRACT_ABI = TreasuryABI;
  var contractInstance2;

  useEffect(() => {
    getSummary();
  }, []);

  const claimAdd = process.env.REACT_APP_CLAIM_ADDR;
  const claimAbi = require("./claimAbi.json");
  //console.log(claimAdd)
  const claimInstance = new web3.eth.Contract(claimAbi, claimAdd, {
    from: ls.get("acct"),
  });
  claimInstance.methods.fetchFees().call({}, function (e, r) {
    if (e != null) {
      return 0;
    }
    setClaimFee(r);
  });
  async function claimMin() {
    try {
      const rate = claimFee;
      //console.log(claimFee)
      const rateWei = Web3.utils.toWei(".0010001", "ether");

      //console.log(rateWei)
      claimInstance.methods
        .claim()
        .send({ from: ls.get("acct"), value: rateWei })
        .on("transactionHash", function (hash) {
          store.addNotification({
            id: "wallet-error",
            title: "Your claim request has been sent",
            message: hash,
            type: "info",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
            },
          });
          getSummary();
        })
        .on("receipt", function (receipt) {
          store.addNotification({
            id: "wallet-error",
            title: "Your claim request is succesful",
            message: "Please wait for your claim to be settled",
            type: "info",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
            },
          });
          getSummary();
        });
    } catch (e) {
      console.log(e);
      store.addNotification({
        id: "e-wallet-error",
        title: "Error",
        message: e.message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      setLoaded(true);
      getSummary();
    }
  }
  const getSummary = async () => {
    //console.log("Reloaded");
    if (!wallet.ethereum) {
      await wallet
        .connect(ls.get("addr"))
        .then(function (e) {
          console.log(e);
        })
        .catch(function (e) {
          console.log(e);
        });
    }
    try {
      await wallet
        .connect(ls.get("addr"))
        .then(function (e) {
          console.log(e);
        })
        .catch(function (e) {
          console.log(e);
        });

      //Dont remove - this is how stale connection objects are handled
      await wallet
        .connect(ls.get("addr"))
        .then(function (e) {
          console.log(e);
        })
        .catch(function (e) {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
    //End dont remove
    //console.log(wallet.balance);
    //console.log(web3.eth.getBalance(ls.get('acct')).then(function(data){console.log(data);}));
    //https://apis.ankr.com/610c87edf4d3419980fd1e0c948d1c19/df041c6c4283774e19a0777f52feee44/binance/full/test
    //const web3 = new Web3('https://tagprotocol:Tag@9876@apis.ankr.com/610c87edf4d3419980fd1e0c948d1c19/df041c6c4283774e19a0777f52feee44/binance/full/test');

    /*if(wallet.status !== "connected") {
        console.log("Not connected Account.js");
        history.push('/wallet');
      }*/
    const response = await fetch(
      process.env.REACT_APP_TREASURY_API + "v1/summary/dashboard/" + walletId
    );
    const jsonDatax = await response.json();

    var indirectCommission = 0;
    var indirectPurchaseVal = 0;
    var indirectSalesNo = 0;
    var directCommission = 0;
    var directPurchaseVal = 0;
    var direcSales = 0;
    var selfPurchase = 0;
    var jsonData = {};
    jsonData["indirectCommission"] = 0;
    jsonData["indirectPurchaseVal"] = 0;
    jsonData["indirectSales"] = 0;
    jsonData["directCommission"] = 0;
    jsonData["directPurchaseVal"] = 0;
    jsonData["directSales"] = 0;
    jsonData["selfPurchase"] = 0;
    jsonData["tokensPurchased"] = 0;
    jsonData["salesFromChain"] = 0;
    if (jsonDatax.hasOwnProperty("indirectCommission")) {
      jsonData["indirectCommission"] = jsonDatax["indirectCommission"];
    }
    if (jsonDatax.hasOwnProperty("indirectPurchaseVal")) {
      jsonData["indirectPurchaseVal"] = jsonDatax["indirectPurchaseVal"];
    }
    if (jsonDatax.hasOwnProperty("indirectSales")) {
      jsonData["indirectSales"] = jsonDatax["indirectSales"];
    }
    if (jsonDatax.hasOwnProperty("directCommission")) {
      jsonData["directCommission"] = jsonDatax["directCommission"];
    }
    if (jsonDatax.hasOwnProperty("directPurchaseVal")) {
      jsonData["directPurchaseVal"] = jsonDatax["directPurchaseVal"];
    }
    if (jsonDatax.hasOwnProperty("directSales")) {
      jsonData["directSales"] = jsonDatax["directSales"];
    }
    if (jsonDatax.hasOwnProperty("selfPurchase")) {
      jsonData["selfPurchase"] = jsonDatax["selfPurchase"];
    }
    if (jsonDatax.hasOwnProperty("tokensPurchased")) {
      jsonData["tokensPurchased"] = jsonDatax["tokensPurchased"];
    }
    if (jsonDatax.hasOwnProperty("salesFromChain")) {
      jsonData["salesFromChain"] = jsonDatax["salesFromChain"];
    }

    setLoaded(true);
    //console.log(jsonData);
    setCommData(jsonData);

    var salesChain = 0;
    var shareChain = 0;
    var levelLocal = 1;
    /*Get data from chain*/
    const w3 = new Web3(process.env.REACT_APP_RPC);
    var accBalance = await w3.eth.getBalance(ls.get("acct"));
    const tagContract = new w3.eth.Contract(
      erc20Abi,
      "0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5",
      { from: ls.get("acct") }
    );
    var TagBal = await tagContract.methods
      .balanceOf(ls.get("acct"))
      .call({}, function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setTagBalance(
            parseFloat(w3.utils.fromWei(res, "ether"), 8).toFixed(3)
          );
        }
      });
    setWalletBalance("" + accBalance);
    const contractInstance = new w3.eth.Contract(
      CONTRACT_ABI,
      CONTRACT_ADDRESS,
      { from: ls.get("acct") }
    );
    await contractInstance.methods
      .fetchUserDetails(walletId)
      .call({}, function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        }
        //console.log(res);
        salesChain = res.sales;
        ls.remove("pendingClaim");
        //console.log(res.share);
        shareChain = res.share;
        levelLocal = res.level;
        ls.set("shareChain", shareChain);
      });
    ls.set("pendingClaim", web3.utils.fromWei(shareChain, "ether"));
    setIndivLev(levelLocal);

    if (!levelLocal) {
      levelLocal = 0;
    }
    const levelFetch = await fetch(
      process.env.REACT_APP_TREASURY_API + "v1/summary/levels/" + levelLocal
    );
    const levelFetchRow = await levelFetch.json();
    setLevelData(parseFloat(levelFetchRow["percent"]) / 100);

    const ins = salesChain - jsonData["dsales"];
    var pclm = shareChain;
    if (ls.get("pendingClaim") === "0") {
      pclm = 0;
    }

    const uClaim = pclm
      ? indirectCommission +
        directCommission -
        parseFloat(web3.utils.fromWei(pclm.toString(), "ether"))
      : 0;

    setClaimData(uClaim);
    if (pclm) {
      setClaimWei(pclm);
      setPclaim(web3.utils.fromWei(pclm.toString(), "ether"));
      //ls.set("pendingClaim",web3.utils.fromWei(ls.get('pendingClaim'), 'ether'));
    } else {
      setPclaim("0");
      ls.set("pendingClaim", "0");
      setClaimWei(0);
    }
    if (!ins) {
      setIsales(0);
    } else {
      setIsales(ins);
    }
    setClaimWei(pclm);
    setSummaryData(jsonData);
    setLoaded(true);

    //Check old contract pending claim
    //console.log(process.env.REACT_APP_CHAINID)

    //End old contract
    if (parseInt(process.env.REACT_APP_CHAINID) == 56) {
      setClaimWei1(0);
      setPclaim1(0);
      ls.set("pendingClaim1", "0");
    }

    //get data from Public ledger for wallet

    const StakeFetch = await fetch(
      process.env.REACT_APP_LEDGER_API +
        "v1/summary/getWallet/" +
        ls.get("acct")
    );
    const StakeRow = await StakeFetch.json();
    const stakeRes = StakeRow[0];
    var stakeData = {};

    stakeData["staked"] = 0;
    stakeData["mined"] = 0;
    stakeData["minted"] = 0;
    stakeData["unminted"] = 0;
    if (stakeRes) {
      if (stakeRes.hasOwnProperty("staked")) {
        stakeData["staked"] = stakeRes["staked"];
      }
      if (stakeRes.hasOwnProperty("mined")) {
        stakeData["mined"] = stakeRes["mined"].toFixed(4);
      }
      if (stakeRes.hasOwnProperty("minted")) {
        stakeData["minted"] = stakeRes["minted"].toFixed(4);
      }
      if (stakeRes.hasOwnProperty("unminted")) {
        stakeData["unminted"] = stakeRes["unminted"].toFixed(4);
      }
    }
    //staked mined minted unminted
    setStakeDate(stakeData);

    //get claim status
    const ClaimFetch = await fetch(
      process.env.REACT_APP_LEDGER_API +
        "v1/summary/getClaims/" +
        ls.get("acct")
    );
    const ClaimRow = await ClaimFetch.json();
    const activeClaims = ClaimRow["claims"];
    setActiveClaim(activeClaims);
    if (activeClaims > 0) {
    }
  };

  let history = useHistory();
  if (query.get("status") && !ls.get("claimed")) {
    query.delete("status");
    ls.set("claimed", "1");
    store.addNotification({
      id: "rec-wallet-error",
      title: "Claim Finished",
      message: "Claim has been completed",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    });
    getSummary();
  }
  const blockNumber = wallet.getBlockNumber();

  const accEp = process.env.REACT_APP_TREASURY_API + "v1/summary/wallet/";
  const [summaryData, setSummaryData] = useState({});
  const [saleData, setSaleData] = useState({});
  const [commData, setCommData] = useState({});
  const [levelData, setLevelData] = useState();
  const [claimData, setClaimData] = useState();
  const [isales, setIsales] = useState();
  const [earn, setEarn] = useState();
  const [indivLev, setIndivLev] = useState();
  const [pclaim, setPclaim] = useState();
  const [claimWei, setClaimWei] = useState();
  const [pclaim1, setPclaim1] = useState();
  const [claimWei1, setClaimWei1] = useState();
  const [walletBalance, setWalletBalance] = useState("");
  const [statusClass, setStatusClass] = useState(
    "Fetching account details from the blockchain"
  );
  const [loaded, setLoaded] = useState(false);
  const [reffData, setReffData] = useState({});
  const [stakeData, setStakeDate] = useState({});
  const [claimFee, setClaimFee] = useState(0);
  const [activeClaim, setActiveClaim] = useState(1);
  const [tagBalance, setTagBalance] = useState(0);

  async function raiseClaim(e) {
    e.preventDefault();
    ls.remove("claimed");
    setStatusClass("Awaiting confirmation from the blockchain");
    setLoaded(false);

    try {
      contractInstance2 = new web3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS,
        { from: ls.get("acct") }
      );
      await contractInstance2.methods
        .claim(claimWei.toString())
        .send({ from: ls.get("acct") })
        .on("receipt", function (receipt) {
          store.addNotification({
            id: "purchase-complete",
            title: "Claim completed",
            message: "Claim of " + claimWei + " is complete",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
          getSummary();
        })
        .on("error", function (error, receipt) {
          getSummary();
        }); /*,function(e,hash){
        
        if(e){
          console.log(e);
            store.addNotification({
              id:"hash-wallet-error",
              title: "Error",
              message: "An error has occured",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000
              }
            });
            setLoaded(true)
            history.push('/account');
          }
          else{
            ls.set("pendingClaim",0);
            store.addNotification({
              id:"hash-wallet-error",
              title: "Claim",
              message: "Completed",
              type: "success",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000
              }
            });
            getSummary();
            history.push('/account');
          }
    });*/
    } catch (e) {
      store.addNotification({
        id: "e-wallet-error",
        title: "Error",
        message: e.message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      setLoaded(true);
      getSummary();
    }
    setLoaded(true);
  }

  async function raiseClaim1(e) {
    e.preventDefault();
    ls.remove("claimed");
    setStatusClass("Awaiting confirmation from the blockchain");
    setLoaded(false);
    const oldC = "0xAb6f08A84b70174b2f27da334C5302611a741509";
    const oldAbi = [
      {
        inputs: [{ internalType: "address", name: "_tagT", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bytes32",
            name: "role",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "previousAdminRole",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "newAdminRole",
            type: "bytes32",
          },
        ],
        name: "RoleAdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bytes32",
            name: "role",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "address",
            name: "account",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "sender",
            type: "address",
          },
        ],
        name: "RoleGranted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bytes32",
            name: "role",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "address",
            name: "account",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "sender",
            type: "address",
          },
        ],
        name: "RoleRevoked",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "_address",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "_share",
            type: "uint256",
          },
        ],
        name: "alloc",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "_address",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "Level",
            type: "uint256",
          },
        ],
        name: "changeLevel",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "_buyer",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "_value",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "_pendingShare",
            type: "uint256",
          },
        ],
        name: "claimEvent",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "_buyer",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "_referer",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "_value",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "_tokens",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "string",
            name: "tokenName",
            type: "string",
          },
        ],
        name: "puchaseEvent",
        type: "event",
      },
      {
        inputs: [],
        name: "DEFAULT_ADMIN_ROLE",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "DisableChainLink",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "EnableChainLink",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "PriceInc",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "Q",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "TagT",
        outputs: [
          { internalType: "contract iTagNFT", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "activate",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_master", type: "address" }],
        name: "changeMaster",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_amt", type: "uint256" }],
        name: "claim",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "currentCtr",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchBaserate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchCL",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_level", type: "uint256" }],
        name: "fetchLevelDetails",
        outputs: [
          { internalType: "uint256", name: "percent", type: "uint256" },
          { internalType: "uint256", name: "sales", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchMaxlevel",
        outputs: [{ internalType: "uint256", name: "level", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchStaticrate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fetchType",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_addr", type: "address" }],
        name: "fetchUserDetails",
        outputs: [
          { internalType: "address", name: "parent", type: "address" },
          { internalType: "uint256", name: "level", type: "uint256" },
          { internalType: "uint256", name: "sales", type: "uint256" },
          { internalType: "uint256", name: "share", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
        name: "getRoleAdmin",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes32", name: "role", type: "bytes32" },
          { internalType: "address", name: "account", type: "address" },
        ],
        name: "grantRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes32", name: "role", type: "bytes32" },
          { internalType: "address", name: "account", type: "address" },
        ],
        name: "hasRole",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "inactivate",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "inc",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_referer", type: "address" },
          { internalType: "string", name: "_value", type: "string" },
        ],
        name: "purchase",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes32", name: "role", type: "bytes32" },
          { internalType: "address", name: "account", type: "address" },
        ],
        name: "renounceRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes32", name: "role", type: "bytes32" },
          { internalType: "address", name: "account", type: "address" },
        ],
        name: "revokeRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_rate", type: "uint256" }],
        name: "setBaseRate",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_addr", type: "address" }],
        name: "setChainLinkAddress",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_ctr", type: "uint256" }],
        name: "setCtr",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_Level", type: "uint256" },
          { internalType: "uint256", name: "_percent", type: "uint256" },
          { internalType: "uint256", name: "_salesLevel", type: "uint256" },
        ],
        name: "setLevel",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_MaxLevel", type: "uint256" },
        ],
        name: "setMaxLevel",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_PriceInc", type: "uint256" },
        ],
        name: "setPriceInc",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_rate", type: "uint256" }],
        name: "setStaticRate",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_child", type: "address" },
          { internalType: "address", name: "parent", type: "address" },
          { internalType: "uint256", name: "level", type: "uint256" },
          { internalType: "uint256", name: "sales", type: "uint256" },
          { internalType: "uint256", name: "share", type: "uint256" },
        ],
        name: "setUser",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_child", type: "address" },
          { internalType: "uint256", name: "_level", type: "uint256" },
        ],
        name: "setUserLevel",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_inc", type: "uint256" }],
        name: "setincrement",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes4", name: "interfaceId", type: "bytes4" },
        ],
        name: "supportsInterface",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
    ];
    const oldContract = new web3.eth.Contract(oldAbi, oldC, {
      from: ls.get("acct"),
    });

    try {
      contractInstance2 = new web3.eth.Contract(oldAbi, oldC, {
        from: ls.get("acct"),
      });
      await contractInstance2.methods
        .claim(claimWei1.toString())
        .send({ from: ls.get("acct") })
        .on("receipt", function (receipt) {
          store.addNotification({
            id: "purchase-complete",
            title: "Claim completed",
            message: "Claim of " + claimWei + " is complete",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
          getSummary();
        })
        .on("error", function (error, receipt) {
          getSummary();
        }); /*,function(e,hash){
          
          if(e){
            console.log(e);
              store.addNotification({
                id:"hash-wallet-error",
                title: "Error",
                message: "An error has occured",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000
                }
              });
              setLoaded(true)
              history.push('/account');
            }
            else{
              ls.set("pendingClaim",0);
              store.addNotification({
                id:"hash-wallet-error",
                title: "Claim",
                message: "Completed",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000
                }
              });
              getSummary();
              history.push('/account');
            }
      });*/
    } catch (e) {
      store.addNotification({
        id: "e-wallet-error",
        title: "Error",
        message: e.message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      setLoaded(true);
      getSummary();
    }
    setLoaded(true);
  }

  return (
    <>
      <BackButton />
      <LoginCheck />
      <div class="app-content">
        <h3>Account Information</h3>
        {loaded === false ? (
          <div class="flex-row">
            {" "}
            <Loader loadingText={statusClass} />
          </div>
        ) : (
          <>
            <ul class="accinfo-list">
              <li>
                <label>BNB</label>
                <span>
                  {parseFloat(
                    web3.utils.fromWei(walletBalance),
                    "ether"
                  ).toFixed(4)}{" "}
                  bnb
                </span>
              </li>
              <li>
                <label>TAG</label>
                <span>{tagBalance} TAG</span>
              </li>
              <li>
                <label>HTAG</label>
                <span>{stakeData.staked} HTAG</span>
              </li>
            </ul>

            <ul class="nav nav-tabs">
              <li class="">
                <a data-toggle="tab" href="#staking" class="active">
                  HASHTAG STAKING{" "}
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#invitation">
                  INVITATION EARNINGS
                </a>
              </li>

              <li>
                <a data-toggle="tab" href="#auction">
                  HASHTAG MARKETPLACE
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#farming">
                  LP TOKEN FARMING
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div id="staking" class="tab-pane active">
                <h4>HASHTAG STAKING</h4>

                <div class="d-flex justify-content-between mb-2">
                  <div class="datasec">
                    <h5>HTAG STAKED</h5>
                    <span>{stakeData.staked} HTAG</span>
                  </div>
                  <div class="datasec">
                    <h5>TAGCOIN MINED</h5>
                    <span>
                      {parseFloat(stakeData.unminted) +
                        parseFloat(stakeData.minted)}{" "}
                      TAG
                    </span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <div class="datasec">
                    <h5>UNMINTED</h5>
                    <span>{stakeData.unminted} TAG</span>
                  </div>
                  <div class="datasec">
                    <h5>MINTED</h5>
                    <span>{stakeData.minted} TAG</span>
                  </div>
                </div>

                <div class="d-flex justify-content-center mt-3">
                  {activeClaim == 0 && (
                    <a href="#" class="btnn" onClick={() => claimMin()}>
                      Mint &amp; Claim
                    </a>
                  )}
                  {activeClaim > 0 && (
                    <a
                      href="#"
                      class="btnn"
                      onClick={() => history.push("/claimStat")}
                    >
                      {" "}
                      Track Your Claim
                    </a>
                  )}
                </div>
              </div>
              <div id="auction" class="tab-pane fade">
                <h4>HASHTAG MARKETPLACE</h4>
                <p class="comingSoonAccInfo">
                  Launches after
                  <br />
                  <strong>The sale of 100,000 HASHTAGS</strong>
                </p>
              </div>
              <div id="farming" class="tab-pane fade">
                <h4>LP TOKEN FARMING</h4>

                <p>
                  <center>
                    <a
                      href="https://tagcoin.io/lpfarm"
                      target="_blank"
                      class="btn btn-primary"
                    >
                      Go to Tagcoin.io
                    </a>
                  </center>{" "}
                </p>
              </div>
              <div id="invitation" class="tab-pane ">
                <h4>INVITATION EARNINGS</h4>
                <div class="d-flex justify-content-between mb-2">
                  <div class="datasec">
                    <h5>DIRECT SALES</h5>
                    <span>{commData.directSales} HTAG</span>
                  </div>
                  <div class="datasec">
                    <h5>INDIRECT SALES</h5>
                    <span>
                      {commData.salesFromChain - commData.directSales} HTAG
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div class="datasec">
                    <h5>SALES</h5>
                    <span>
                      {(
                        parseFloat(commData.directPurchaseVal) +
                        parseFloat(commData.indirectPurchaseVal)
                      ).toFixed(4)}{" "}
                      BNB
                    </span>
                  </div>
                  <div class="datasec">
                    <h5>COMMISSION</h5>
                    <span>
                      {(
                        commData.indirectCommission + commData.directCommission
                      ).toFixed(4)}{" "}
                      BNB
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="datasec">
                    <h5>CLAIMED </h5>
                    <span>
                      {(
                        commData.indirectCommission +
                        commData.directCommission -
                        pclaim -
                        pclaim1
                      ).toFixed(4)}{" "}
                      BNB
                    </span>
                  </div>
                  <div class="datasec">
                    <h5>UNCLAIMED</h5>
                    <span>
                      {(
                        parseFloat(ls.get("pendingClaim")) +
                        parseFloat(ls.get("pendingClaim1"))
                      ).toFixed(4)}{" "}
                      BNB
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  {(function () {
                    if (ls.get("pendingClaim1") !== "0") {
                      return (
                        <button
                          type="button"
                          onClick={raiseClaim1}
                          class="btnn vclaim"
                        >
                          Claim V2
                        </button>
                      );
                    }
                  })()}
                  {(function () {
                    if (ls.get("pendingClaim") !== "0") {
                      return (
                        <button
                          type="button"
                          onClick={raiseClaim}
                          class="btnn vclaim"
                        >
                          Claim
                        </button>
                      );
                    } else {
                      return (
                        <button type="button" disabled class="btnn vclaim">
                          No V3 Pending Claim
                        </button>
                      );
                    }
                  })()}
                  <br />
                </div>
              </div>
            </div>
          </>
        )}
        <div class="app-btnsec">
          <a href="/dashboard" class="btn">
            Back
          </a>
        </div>
      </div>
    </>
  );
}
export default Account;
