import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import Loader from '../components/Loader';
import { store } from 'react-notifications-component';
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import abiVal from '../data/abi.json';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Web3 = require('web3'); 
const web3 = new Web3('https://bsc-dataseed4.binance.org/'); 
const CONTRACT_ADDRESS = abiVal.pswapcontract;
const CONTRACT_ABI = abiVal.pswapabi;

const TAG_ADDRESS = abiVal.tagcontract;
const TAG_ABI = abiVal.tagabi;

const BUSD_ADDRESS = abiVal.firsttokencontract;
const BUSD_ABI = abiVal.firsttokenabi;

function TradePan(){
    const wallet = useWallet();
    let history = useHistory();
    const webb3 =  new Web3(wallet.ethereum);
    const contractInstance = new webb3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    const tagcontractInstance = new webb3.eth.Contract(TAG_ABI, TAG_ADDRESS);
    const busdcontractInstance = new webb3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);

    //we can interact with BNB directly from the pancakeswap router contract

    const [tokname, setTokname] = useState('BNB');
    const [from, setFrom] = useState('BNB');
    const [to, setTo] = useState('TAG');
    const [fromval, setFromval] = useState('');
    const [toval, setToval] = useState('');
    const [tokbalance, setTokbal] = useState('');
    const [tagbalance, setTagbal] = useState('');
    const [tagprice, setTagprice] = useState('');
    const [tokprice, setTokprice] = useState('');
    const [loadingg,setLoadingg] = useState(false);
    const [rangeval,setRangeval] = useState(10);
    const [activecontract,setActiveContract] = useState('')
    const [cpy,setCpy] = useState(false)

    useEffect(() => {
        getTokbal();
    }, []);
    
    
function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

  //to not round values while showing balance
  function noround(val,x){
    var float= parseFloat(val).toFixed(18);
    var num = float.toString(); 
    var n = num.slice(0, (num.indexOf("."))+(x+1)); 
    return (n);
  }


    const getTokbal = async () => {
        var name = localStorage.getItem('Tokname');
        if(name)
        setTokname(name);
        else
        {
          localStorage.setItem('Tokname','BNB');
          var name = 'BNB';
        }
        const w3 =  new Web3('https://bsc-dataseed4.binance.org/'); 
        const invalue = 1;
        const finval = invalue.toString();
        const contractInstance2 = new w3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
        const tagcontractInstanceto = new w3.eth.Contract(TAG_ABI, TAG_ADDRESS);
        const busdcontractInstanceto = new w3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
        
        const tagadd = TAG_ADDRESS; //stores contract address of tag -- for testing TAG

        //add here if there are more tokens
        if(name=="BNB")
        {var tokencaddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
           if(from!="BNB" && to!="BNB")
            {
              setFrom('BNB');
              setTo('TAG');
            }
        }
        else
        if(name=="BUSD")
        {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        if(from!="BUSD" && to!="BUSD")
         {
           setFrom('BUSD');
           setTo('TAG');
         }
        }
        setActiveContract(tokencaddress)
        //for calculating 1 token = x TAG
        await contractInstance2.methods.getAmountsOut(w3.utils.toWei(finval),[tokencaddress,tagadd]).call(function (err, res) {
            if (err) {
              console.log("An error occured", err)
              return
            }
            setTokprice(w3.utils.fromWei(res[1]));
          })

        //for calculating 1 TAG = x token
        await contractInstance2.methods.getAmountsOut(w3.utils.toWei(finval),[tagadd,tokencaddress]).call(function (err, res) {
            if (err) {
              console.log("An error occured", err)
              return
            }
            setTagprice(w3.utils.fromWei(res[1]));
          })

          //for calculating token balance like BNB,BUSD,...
          if(name=="BNB")
          {
            setTokbal('BNBval');
          }
          else
          if(name=="BUSD"){
          if(tokbalance!= ''){ await timeout(8000); }
          const test = await busdcontractInstanceto.methods.balanceOf(ls.get('acct')).call();
          await busdcontractInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
            if (error != null) {
                console.log(error)
                return;
            }
            else
            {
            setTokbal(res);}
            })
          }
          //add here for more tokens

          //for calculating tag balance -- testing with TAG
          if(tagbalance!= ''){ await timeout(8000); }
          const test = await busdcontractInstanceto.methods.balanceOf(ls.get('acct')).call();
          await tagcontractInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
            if (error != null) {
                console.log(error)
                return;
            }
            else
            {
            setTagbal(res);}
            })
       };


//autocompletes To input field based on value on From field
    async function handlefromChange(val) {
        setFromval(val);
        var name = localStorage.getItem('Tokname');
        const tagadd = TAG_ADDRESS;
        if(name=="BNB")
        {var tokencaddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";}
        else if(name=="BUSD")
        {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";}

        if(isNaN(val) || val == '')
        {
            setFromval('');
            setToval('');
        }
        else{
            if(from==name){
        await contractInstance.methods.getAmountsOut(webb3.utils.toWei(val),[tokencaddress,tagadd]).call(function (err, res) {
            if (err) {
              console.log("An error occured", err)
              return
            }
            setToval(parseFloat(webb3.utils.fromWei(res[1])).toFixed(10));
          })}
          else{
            await contractInstance.methods.getAmountsOut(webb3.utils.toWei(val),[tagadd,tokencaddress]).call(function (err, res) {
                if (err) {
                  console.log("An error occured", err)
                  return
                }
                setToval(parseFloat(webb3.utils.fromWei(res[1])).toFixed(10));
              })}
            }
      }



//autocompletes From input field based on value on To field
    async function handletoChange(val) {
        setToval(val);
        var name = localStorage.getItem('Tokname');
        const tagadd = TAG_ADDRESS;
        if(name=="BNB")
        {var tokencaddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";}
        else if(name=="BUSD")
        {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";}
        if(isNaN(val) || val == '')
        {
            setFromval('');
            setToval('');
        }
        else
        {
            if(from==name){
        await contractInstance.methods.getAmountsIn(webb3.utils.toWei(val),[tokencaddress,tagadd]).call(function (err, res) {
            if (err) {
              console.log("An error occured", err)
              return
            }
            setFromval(parseFloat(webb3.utils.fromWei(res[0])).toFixed(10));
          })
            }
            else{
              await contractInstance.methods.getAmountsIn(webb3.utils.toWei(val),[tagadd,tokencaddress]).call(function (err, res) {
                  if (err) {
                    console.log("An error occured", err)
                    return
                  }
                  setFromval(parseFloat(webb3.utils.fromWei(res[0])).toFixed(10));
                })
            }
        }
      }

//handle down-arrow click
    function handleFromto(){
      var name = localStorage.getItem('Tokname');
        if(from==name)
            { 
                let x = fromval;
            setFrom('TAG');
            setTo(name);
            setFromval(toval);
            setToval(x);
            }
        else
        { 
            let x = fromval;
            setFrom(name);
        setTo('TAG');
        setFromval(toval);
        setToval(x);
        }
      }

//function to perform the swap
    async function swapToken() {
      setLoadingg(true);
      var name = localStorage.getItem('Tokname');
      const tagadd = TAG_ADDRESS;
      if(name=="BNB")
      {var tokencaddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";}
      else if(name=="BUSD")
      {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";}
        let endtime = (Math.floor(Date.now()/1000)+600);
        let gaslim = await webb3.eth.estimateGas({from:wallet.account});
        let gaspri = await webb3.eth.getGasPrice();


        if(from=='BNB')
        {
          var mintag = (parseFloat(((100-rangeval)/100)*toval).toFixed(16)).toString();
        await contractInstance.methods.swapExactETHForTokens(
                  webb3.utils.toWei(mintag,'ether'),
                  [tokencaddress,tagadd],
                  wallet.account,
                  endtime).send(
                  {
                      from:wallet.account,
                      gasLimit: 500000,
                      gasPrice: webb3.utils.toWei("10", "gwei"),
                      value: webb3.utils.toWei(fromval)
                  }
                  ).on('transactionHash',async function(hash){
                      console.log("Success");
                      await getTokbal();
                      setLoadingg(false);
                      
                      store.addNotification({
                        id:"purchase-complete",
                        title: "Swap completed",
                        message: "Swap is complete",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000
                        }
                      });
            }).on('error', function(error, hash) {
            console.log(error);
            setLoadingg(false);
              store.addNotification({
                id:"swap-error",
                title: "Error",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 8000
                }
              });
            });
          }
        else
        if(to=='BNB')
        {
            let fromconaddr = CONTRACT_ADDRESS;
            
          var minbnb = (parseFloat(((100-rangeval)/100)*toval).toFixed(16)).toString();

            await tagcontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(fromval)).send(
                {
                    from:wallet.account,
                    gasLimit: gaslim,
                    gasPrice: gaspri
                }
                ).on('transactionHash',async function(hash){
                    console.log("Approved");
                    await contractInstance.methods.swapExactTokensForETH(
                        webb3.utils.toWei(fromval),
                        webb3.utils.toWei(minbnb,'ether'),
                        [tagadd,tokencaddress],
                        wallet.account,
                        endtime).send(
                            {
                                from:wallet.account,
                                gasLimit: 500000,
                                gasPrice: webb3.utils.toWei("10", "gwei")
                            }
                            ).on('transactionHash', async function(hash){
                                console.log(hash);
                                await getTokbal();
                                setLoadingg(false);
                                
                              store.addNotification({
                                id:"purchase-complete",
                                title: "Swap completed",
                                message: "Swap is complete",
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                  duration: 5000
                                }
                              });
                            }).on('error', function(error, hash) {
                                console.log('error');
                                setLoadingg(false);
                                store.addNotification({
                                  id:"swap-error",
                                  title: "Error",
                                  message: error.message,
                                  type: "danger",
                                  insert: "top",
                                  container: "top-center",
                                  animationIn: ["animate__animated", "animate__fadeIn"],
                                  animationOut: ["animate__animated", "animate__fadeOut"],
                                  dismiss: {
                                    duration: 8000
                                  }
                                });
                            });
          }).on('error', function(error, hash) {
            setLoadingg(false);
            
            store.addNotification({
              id:"swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000
              }
            });
          });
           
        }
        else
        {
            let fromconaddr = CONTRACT_ADDRESS;
            var name = localStorage.getItem('Tokname');
            const tagadd = TAG_ADDRESS;
            if(name=="BUSD")
            {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";}
            
            //add here and change contractinstance for new tokens

            var minouttok = (parseFloat(((100-rangeval)/100)*toval).toFixed(16)).toString();

            if(from=="TAG"){
            await tagcontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(fromval)).send(
                {
                    from:wallet.account,
                    gasLimit: gaslim,
                    gasPrice: gaspri
                }
                ).on('transactionHash',async function(hash){
                    console.log("Approved");
                    await contractInstance.methods.swapExactTokensForTokens(
                        webb3.utils.toWei(fromval),
                        webb3.utils.toWei(minouttok,'ether'),
                        [tagadd,tokencaddress],
                        wallet.account,
                        endtime).send(
                            {
                                from:wallet.account,
                                gasLimit: 500000,
                                gasPrice: webb3.utils.toWei("10", "gwei")
                            }
                            ).on('transactionHash', async function(hash){
                                console.log(hash);
                                await getTokbal();
                                setLoadingg(false);
                                
                              store.addNotification({
                                id:"purchase-complete",
                                title: "Swap completed",
                                message: "Swap is complete",
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                  duration: 5000
                                }
                              });
                            }).on('error', function(error, hash) {
                                console.log('error');
                                setLoadingg(false);
                                store.addNotification({
                                  id:"swap-error",
                                  title: "Error",
                                  message: error.message,
                                  type: "danger",
                                  insert: "top",
                                  container: "top-center",
                                  animationIn: ["animate__animated", "animate__fadeIn"],
                                  animationOut: ["animate__animated", "animate__fadeOut"],
                                  dismiss: {
                                    duration: 8000
                                  }
                                });
                            });
          }).on('error', function(error, hash) {
            setLoadingg(false);
            
            store.addNotification({
              id:"swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000
              }
            });
          });
        }
         else if(to=="TAG"){
            await busdcontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(fromval)).send(
                {
                    from:wallet.account,
                    gasLimit: gaslim,
                    gasPrice: gaspri
                }
                ).on('transactionHash',async function(hash){
                    console.log("Approved");
                    await contractInstance.methods.swapExactTokensForTokens(
                        webb3.utils.toWei(fromval,'ether'),
                        webb3.utils.toWei(minouttok,'ether'),
                        [tokencaddress,tagadd],
                        wallet.account,
                        endtime).send(
                            {
                                from:wallet.account,
                                gasLimit: 500000,
                                gasPrice: webb3.utils.toWei("10", "gwei")
                            }
                            ).on('transactionHash', async function(hash){
                                console.log(hash);
                                await getTokbal();
                                setLoadingg(false);
                                
                              store.addNotification({
                                id:"purchase-complete",
                                title: "Swap completed",
                                message: "Swap is complete",
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                  duration: 5000
                                }
                              });
                            }).on('error', function(error, hash) {
                                console.log('error');
                                setLoadingg(false);
                                store.addNotification({
                                  id:"swap-error",
                                  title: "Error",
                                  message: error.message,
                                  type: "danger",
                                  insert: "top",
                                  container: "top-center",
                                  animationIn: ["animate__animated", "animate__fadeIn"],
                                  animationOut: ["animate__animated", "animate__fadeOut"],
                                  dismiss: {
                                    duration: 8000
                                  }
                                });
                            });
          }).on('error', function(error, hash) {
            setLoadingg(false);
            
            store.addNotification({
              id:"swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000
              }
            });
          });
        }
        }
    }

    function handleMaxfrom(f,bnb,tok,tag){
      if(f=="BNB")
      {
        var x =noround(parseFloat(web3.utils.fromWei(bnb), 'ether'),8);
        setFromval(x);
        handlefromChange(x);
      }
      else if(f=="TAG")
      {
        var x =noround(parseFloat(web3.utils.fromWei(tag), 'ether'),8);
        setFromval(x);
        handlefromChange(x);
      }
      else
      {
        var x =noround(parseFloat(web3.utils.fromWei(tok), 'ether'),8);
        setFromval(x);
        handlefromChange(x);
      }
    }

    function handleMaxto(f,bnb,tok,tag){
      if(f=="BNB")
      {
        var x =noround(parseFloat(web3.utils.fromWei(bnb), 'ether'),8);
        setToval(x);
        handletoChange(x);
      }
      else if(f=="TAG")
      {
        var x =noround(parseFloat(web3.utils.fromWei(tag), 'ether'),8);
        setToval(x);
        handletoChange(x);
      }
      else
      {
        var x =noround(parseFloat(web3.utils.fromWei(tok), 'ether'),8);
        setToval(x);
        handletoChange(x);
      }
    }

    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div className="app-content trcontent">
               		
   <div className="tagtrade-content">
		<h3>Trade Tagcoin</h3>

			<div className="tagtrade">
		<ul className="nav nav-tabs">
			<li className=""><a href="" onClick={() => localStorage.setItem('Tokname', 'BNB')} className={tokname==="BNB"? "active show tokbutton" : "tokbutton"} style={{float:"right"}}>TAG/BNB</a></li>
			<li><a href="" onClick={() => localStorage.setItem('Tokname', 'BUSD')} className={tokname==="BUSD"? "active show tokbutton" : "tokbutton"} >TAG/BUSD</a></li>
		 </ul>
			</div>
		<ul className="nav nav-tabs">
			<li className=""><a href="" className="active show">SWAP</a></li>
			<li><a href="" onClick={() => {history.push('/liquidityPancake'); }}>ADD LIQUIDITY</a></li>
		 </ul>
    </div> 
<div className="body">
    <div className="containerr preload">
    <ul className="accinfo-list pb-2" style={{marginTop:"5px"}}>
				<li><label>{tokname} BALANCE</label><span>{noround(parseFloat(web3.utils.fromWei(tokbalance==="BNBval"? wallet.balance : tokbalance), 'ether'),4)} {tokname}</span></li>
				<li><label>TAG BALANCE</label><span>{noround(parseFloat(web3.utils.fromWei(tagbalance), 'ether'),4)} TAG</span></li> {/* replace with TAG */}
			</ul>
    </div>
    <div className="form-container">
        <div className="field-container" style={{background: "#c9d1d8",padding: "12px"}}>
            <label className="labl" htmlFor="name" style={{padding: "5px"}}>FROM</label>
            <div style={{display:"flex"}}>
            <input id="bnb" className="inp" maxLength="10" type="text" name="fromvalue" style={{background: "transparent",border: "0px",outline:"none"}} placeholder="0.0" onChange={e => handlefromChange(e.target.value)} value={fromval}/>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",fontWeight:"700",color:"#1e82ce",cursor:"pointer",outline:"0px"}} onClick={e=>handleMaxfrom(from,wallet.balance,tokbalance,tagbalance)}>MAX</button>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",outline:"0px"}}>{from}</button>
            </div>
        </div>
        <div className="downarroww">
        <a onClick={handleFromto}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/></svg> </a>
        </div>
        <div className="field-container" style={{background: "#c9d1d8",padding: "12px",marginTop:"3%"}}>
            <label className="labl" htmlFor="name" style={{padding: "5px"}}>TO</label>
            <div style={{display:"flex"}}>
            <input id="tag" className="inp" maxLength="20" type="text" name="tovalue" style={{background: "transparent",border: "0px",outline:"none"}} placeholder="0.0" onChange={e =>handletoChange(e.target.value)} value={toval}/>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",fontWeight:"700",color:"#1e82ce",cursor:"pointer",outline:"0px"}} onClick={e=>handleMaxto(to,wallet.balance,tokbalance,tagbalance)}>MAX</button>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",outline:"0px"}}>{to}</button>
            </div>
        </div>
				<div className="tagtrade" style={{marginTop: "7%",marginBottom: "2%",gridColumn: "1/3"}}>
        <div className="tagbnb d-flex justify-content-center mb-1">
				<label>Price:</label><span className="ml-1 mr-1">1 TAG</span> <label>=</label> <span className="ml-1">{noround(parseFloat(tagprice),8)} {tokname}</span>  {/* replace with TAG */}
			</div>
			<div className="tagbnb d-flex justify-content-center mb-1">
				<label>Price:</label><span className="ml-1 mr-1">1 {tokname}</span> <label>=</label> <span className="ml-1">{noround(parseFloat(tokprice),4)} TAG</span> {/* replace with TAG */}
			</div>
			<div className="tagbnb d-flex justify-content-center">
				<label>Slippage Tolerance:</label><span className="ml-1" style={{color:"#ffb16e"}}>{rangeval}%</span>
			</div>
			<div className="tagbnb d-flex justify-content-center">
			<input className="slippageslide" type="range" value={rangeval} min="0" max="15" onChange={e => setRangeval(e.target.value)} onMouseMove={e => setRangeval(e.target.value)}/>
			</div>
			<div className="tagbnb d-flex justify-content-center">
				<label>Minimum Received:</label><span className="ml-1">{noround(parseFloat(((100-rangeval)/100)*toval),9)} {to}</span>
			</div>
			</div>
        <div className="field-container trload" style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}>
            <button className={ loadingg === true ? "swapbutton" : "swapbutton disnone"} onClick={swapToken}>SWAP TOKEN<Loader/></button>
        </div>
        <div className="field-container trload" style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}>
           
           <center><a href={"https://pancakeswap.finance/swap?outputCurrency=0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5&inputCurrency="+activecontract} target="_blank" style={{marginTop:"10px",padding:"10px","display":"block"}}>Trade on Pancakeswap</a></center> 
        <center>
        <p style={{fontSize:"12px"}}>Tagcoin Address <br/> 0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5</p>
        <CopyToClipboard text="0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5" onCopy={() => setCpy(true)}>{cpy ? <button class="copyTagCoinAdd">Copied !</button> : <button class="copyTagCoinAdd copied">Copy Tagcoin Address</button>}</CopyToClipboard>
        </center>
        </div>
        <div style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}><center><img src="https://tagprotocol.s3.us-east-1.amazonaws.com/POWERED%20BY%20PANCAKESWAP.png" alt="" style={{"margin":"auto"}}/></center></div>
    </div>
    </div>
    <div className="app-btnsec">
			<a href="/selectTrade" className="btn mr-2">Back</a>
		</div>
	</div>
      
    </>
    );
}
export default TradePan;