import "./App.css";
import RefLanding from "./components/RefLanding";
import Index from "./components/Index";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Refer from "./components/Refer";
import Wallet from "./components/Wallet";
import Dashboard from "./components/Dashboard";
import Account from "./components/Account";
import Search from "./components/Search";
import Myhashtag from "./components/Myhashtag";
import Refearn from "./components/Refearn";
import Trackref from "./components/Trackref";
import LatestRefTrans from "./components/LatestRefTrans";
import Reftree from "./components/Reftree";
import OtherAcc from "./components/OtherAcc";
import Exref from "./components/Exref";
import ReactNotification from "react-notifications-component";
import Recenttags from "./components/Recenttags";
import MultiSearch from "./components/MultiSearch";
import "react-notifications-component/dist/theme.css";
import ls from "local-storage";
import InnerResearch from "./components/InnerResearch";
import Staking from "./components/Staking";
import Trade from "./components/Trade";
import Auction from "./components/Auction";
import MainLanding from "./components/mainLanding";
import tagInfo from "./components/TagInfo";
import Grades from "./components/Grades";
import Transfer from "./components/Transfer";
import Liquidity from "./components/Liquidity";
import ClaimStat from "./components/ClaimStatus";
import SelectTrade from "./components/SelectTrade";
import TradePan from "./components/TradePancake";
import LiquidityPan from "./components/LiquidityPancake";
import TradeSushi from "./components/TradeSushi";
import LiquiditySushi from "./components/LiquiditySushi";
import PrivacyPolicy from "./components/PrivacyPolicy";

import { BrowserRouter as Router, Route } from "react-router-dom";

require("dotenv").config();
function App() {
  if (ls.get("addr") == null) {
    ls.remove("addr");
    ls.remove("acct");
  }

  return (
    <div className="App">
      <ReactNotification />
      <Router>
        <Header />
        <Route path="/" exact component={MainLanding} />
        <Route path="/landing" exact component={MainLanding} />
        <Route path="/welcome" exact component={RefLanding} />
        <Route path="/refer" exact component={Refer} />
        <Route path="/wallet" exact component={Wallet} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/account" exact component={Account} />
        <Route path="/search" exact component={Search} />
        <Route path="/myhashtags" exact component={Myhashtag} />
        <Route path="/refearn" exact component={Refearn} />
        <Route path="/trackRef" exact component={Trackref} />
        <Route path="/LatestRefTrans" exact component={LatestRefTrans} />
        <Route path="/reftree" exact component={Reftree} />
        <Route path="/otherAccount/:id" exact component={OtherAcc} />
        <Route path="/taginfo/:id" exact component={tagInfo} />
        <Route path="/transfer/:id" exact component={Transfer} />
        <Route path="/exref/:id/:type?" exact component={Exref} />
        <Route path="/otherAccount" exact component={OtherAcc} />
        <Route path="/recentHashtags" exact component={Recenttags} />
        <Route path="/MultiSearch" exact component={MultiSearch} />
        <Route path="/Research" exact component={InnerResearch} />
        <Route path="/staking" exact component={Staking} />
        <Route path="/trade" exact component={Trade} />
        <Route path="/auction" exact component={Auction} />
        <Route path="/Grades" exact component={Grades} />
        <Route path="/liquidity" exact component={Liquidity} />
        <Route path="/claimStat" exact component={ClaimStat} />
        <Route path="/selectTrade" exact component={SelectTrade} />
        <Route path="/tradePancake" exact component={TradePan} />
        <Route path="/liquidityPancake" exact component={LiquidityPan} />
        <Route path="/tradeSushi" exact component={TradeSushi} />
        <Route path="/liquiditySushi" exact component={LiquiditySushi} />
        <Route path="/privacypolicy" exact component={PrivacyPolicy} />
      </Router>
    </div>
  );
}

export default App;
