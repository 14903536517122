import React,{ useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from './sharedBackButton';
import AppFooter from "./Appfooter";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoginCheck from "./LoginCheck"; //<LoginCheck/>
import ls from 'local-storage'
import InfiniteScroll from "react-infinite-scroll-component";

function LatestRefTrans(){

   useEffect(() => {
       initialData();
    }, []);
    const [recentData, setRecentData] = useState([]);
    const [recentIData, setRecentIData] = useState([]);
    const wallet = useWallet()
    let history = useHistory();
    const [count, setCount] = useState(0);
    const [statex, setStatex] = useState({hasMore:true, items:[]});
    const [page,setPage] = useState(1);
    const [maxPage,setMaxPage] = useState()
    const walletId = ls.get('acct');
   
    const fetchMoreData = async () => {
      var hasMore = false;
      var retData = []
      
      if((maxPage>1&&page!=maxPage+1) ){
        var lpage = page+1
        setPage(lpage);
        hasMore = true;
        var mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/reft/"+ls.get('acct')+"/"+lpage;
       
        
        
        
        const response = await fetch(mytags);
        const jsonData = await response.json();
   
        retData = jsonData['data']
        setCount(jsonData['total']);
        if(parseInt(jsonData['totalPages'])>page){
          hasMore = true 
        }
        if(parseInt(jsonData['totalPages'])==page){
          hasMore = false 
        }
      }
      setStatex({items:statex.items.concat(retData), hasMore:hasMore})
     
      
    };


    const initialData = async() => {
      const mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/reft/"+ls.get('acct')+"/"+page;
      const response = await fetch(mytags);
      const jsonData = await response.json();
      console.log(jsonData)
      var hasMore = false;
      setCount(jsonData['total']);
      if(parseInt(jsonData['totalPages'])>page){
        hasMore = true 
      }
      
      setStatex({
        items: jsonData['data'],
        hasMore: hasMore
      });
      setMaxPage(parseInt(jsonData['totalPages']))
    }
    return(
        <>
        <LoginCheck/>
     <BackButton/>
  	<div class="app-content">
		<h5 style={{width:"100%", textAlign:"center", marginBottom:"15px"}}>Latest Referral Transactions</h5>
		
		
		
    <InfiniteScroll
          dataLength={statex.items.length}
          next={fetchMoreData}
          hasMore={statex.hasMore}
          loader={<span class="loadMore">Scroll to load more</span>}
          
          endMessage={
            <p style={{ textAlign: "center" }}>
              
            </p>
          }
        ><table class="table table-striped table-bordered table-sm " >
          <thead>
                <tr>
                  <th scope="col">Hashtag</th>
                  
                  <th scope="col">Share</th>
                 
                  <th scope="col"></th>
                </tr>
              </thead><tbody>
          {statex.items.map((item, index) => (
            <tr class="direct" class="Row_htag" ><th scope="row"  style={{maxWdith:"100px",overflow:"hidden"}}>{(item.tag).substring(0,20)}</th>
            <td>{(item.commission).toFixed(5)}</td>
            
            <td><a href={"https://bscscan.com/tx/"+item.transactionHash} class="text-decoration-none" target="_blank" rel="noreferrer">&#x1F50D;</a></td>
          </tr>
          ))}</tbody></table>
        </InfiniteScroll>
			
  
		

		 
		
		<div class="app-btnsec">
			<a href="/trackRef" class="btn">Back</a>
		</div>
	</div>
  

   

    </>
    );
}
export default LatestRefTrans;