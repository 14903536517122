import React,{ useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from './sharedBackButton';
import AppFooter from "./Appfooter";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoginCheck from "./LoginCheck"; //<LoginCheck/>
import ls from 'local-storage'

function Trackref(){

   useEffect(() => {
      levelfetch();
    }, []);
    const [recentData, setRecentData] = useState([]);
    const wallet = useWallet()
    let history = useHistory();
    
    
    async function levelfetch(){
       //const url = process.env.REACT_APP_TREASURY_API+"v1/summary/refearning/"+ls.get('acct');
       
       const response = await fetch(process.env.REACT_APP_TREASURY_API+"v1/summary/dashboard/"+ls.get('acct'));
      const jsonDatax = await response.json();

      var indirectCommission = 0
      var indirectPurchaseVal = 0
      var indirectSalesNo = 0
      var directCommission = 0
      var directPurchaseVal = 0
      var direcSales = 0
      var selfPurchase = 0
      var jsonData = {}
      jsonData['indirectCommission'] = 0
      jsonData['indirectPurchaseVal'] = 0
      jsonData['indirectSales'] = 0 
      jsonData['directCommission'] = 0 
      jsonData['directPurchaseVal'] = 0
      jsonData['directSales'] = 0
      jsonData['selfPurchase'] = 0

      if (jsonDatax.hasOwnProperty('indirectCommission')){
        jsonData['indirectCommission'] = (jsonDatax['indirectCommission']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('indirectPurchaseVal')){
        jsonData['indirectPurchaseVal'] = (jsonDatax['indirectPurchaseVal']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('indirectSales')){
        jsonData['indirectSales'] = (jsonDatax['indirectSales']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('directCommission')){
        jsonData['directCommission'] = (jsonDatax['directCommission']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('directPurchaseVal')){
        jsonData['directPurchaseVal'] = (jsonDatax['directPurchaseVal']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('directSales')){
        jsonData['directSales'] = (jsonDatax['directSales']).toFixed(4)
      }
      if (jsonDatax.hasOwnProperty('selfPurchase')){
        jsonData['selfPurchase'] = (jsonDatax['selfPurchase']).toFixed(4)
      }


      console.log(jsonData);
      setRecentData(jsonData)
    }
    
    return(
        <>
         <LoginCheck/>

<BackButton/>
      <div class="app-content">
		<h3>Invite Earnings</h3>
		
    <h6>DIRECT SALES</h6>
    <ul class="accinfo-list">
			<li><label>Quantity</label><span>{recentData.directSales} HTAG</span></li>
			<li><label>Amount</label><span>{recentData.directPurchaseVal} BNB</span></li>
			<li><label>Commission</label><span> {recentData.directCommission} BNB</span></li>
			
		</ul>

    <h6>INDIRECT SALES</h6>
    <ul class="accinfo-list">
			<li><label>Quantity</label><span>{recentData.indirectSales} HTAG</span></li>
			<li><label>Amount</label><span>{recentData.indirectPurchaseVal} BNB</span></li>
			<li><label>Commission</label><span> {recentData.indirectCommission} BNB</span></li>
			
		</ul>
          
    <ul class="dl-lists">
			<li><a onClick={() => {history.push('/latestRefTrans'); }} >Latest Referral Transactions</a></li>
      <li><a onClick={() => {history.push('/otherAccount'); }} >Track Other Wallets</a></li>
      <li><a onClick={() => {history.push('/reftree'); }} >Invite Tree</a></li>
			
		</ul>
          
		<div class="app-footer bg-white pt-0">
			
		</div>
		<div class="app-btnsec">
			<a href="/refearn" class="btn mr-2">Back</a>
		</div>
	</div>
   

    </>
    );
}
export default Trackref;