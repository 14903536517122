import React, { useRef, useState, useEffect } from "react";
import AppFooter from "./Appfooter";


const Web3 = require('web3'); 


const summaryUrl = process.env.REACT_APP_TREASURY_API+"v1/summary/";
function Recenttags(){
  document.body.classList.remove("bluebg");
    document.body.classList.remove("app-body");
    document.body.classList.add("patternbody");
    document.body.classList.add("app-body");
    const [summaryData, setSummaryData ] = useState({});
    const [recentData, setRecentData] = useState([]);
    const [recentData1, setRecentData1] = useState([]);
    const [recentData2, setRecentData2] = useState([]);
    const [errData, setErrData ] = useState('');
    const [kwData, setKwData ] = useState('');
    const [disData, setDisData ] = useState();
    const [disable, setDisable] = useState(false);
    const [bnbPrice,setBnbPrice]=useState();
    const [research, setResearch] = useState([]);
    const [resan, setResan] = useState([]);
    const [variants,setVariants] = useState([]);
    const [curpage, setCurpage] = useState();
    const refPurchase = useRef([]);
    const validateUrl = process.env.REACT_APP_TREASURY_API+"v1/summary/availability/";
    const CHAINLINK_ORACLE_ABI = [
        {
            "constant": true,
            "inputs": [],
            "name": "latestAnswer",
            "outputs": [
                {
                    "name": "",
                    "type": "int256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        },
        {
            "constant": true,
            "inputs": [],
            "name": "latestTimestamp",
            "outputs": [
                {
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }
      ]
      const ORACLE_ADDRESS = process.env.REACT_APP_CHAINLINK
      const w3 =  new Web3(process.env.REACT_APP_RPC); 
    let oracle = new w3.eth.Contract(CHAINLINK_ORACLE_ABI, ORACLE_ADDRESS);
    oracle.methods.latestAnswer().call({}, function(error, res) {
        if (error != null) {
            console.log(error)
            return;
        }
        //console.log("BNBUSD:" ,res);
        var tokenPrice = (parseFloat(1/(res/100000000)))*250;
        var clearance = 0;//(parseFloat(1/(res/100000000)))*5;
        var base18 = Web3.utils.toWei(''+((tokenPrice+clearance).toFixed(18)),'ether');
        setBnbPrice(base18);
    });
    async function paginate(page=0){
      console.log('Page is '+page);
      setCurpage(page);
      
      if(page!==0){
        page = page+30;
        refPurchase.current.removeAttribute("disabled")
      }
      else {
        refPurchase.current.setAttribute("disabled","disabled")
      }
      const recents = process.env.REACT_APP_TREASURY_API+"v1/summary/recents/10/"+page;
      const recentList = await fetch(recents);
      const recentListRows =  await  recentList.json() ;
      const recentListDis = await recentListRows.map(function(k,i){
        return <li class="recentHashPromo" key={i}>#{k.hashtag}</li>;
      });
      setRecentData(recentListDis);
      const recents1 = process.env.REACT_APP_TREASURY_API+"v1/summary/recents/10/"+(page+10);
      const recentList1 = await fetch(recents1);
      const recentListRows1 =  await  recentList1.json() ;
      const recentListDis1 = await recentListRows1.map(function(k,i){
        return <li class="recentHashPromo" key={i}>#{k.hashtag}</li>;
      });
      setRecentData1(recentListDis1);

      const recents2 = process.env.REACT_APP_TREASURY_API+"v1/summary/recents/10/"+(page+20);
      const recentList2 = await fetch(recents2);
      const recentListRows2 =  await  recentList2.json() ;
      const recentListDis2 = await recentListRows2.map(function(k,i){
        return <li class="recentHashPromo" key={i}>#{k.hashtag}</li>;
      });
    
      setRecentData2(recentListDis2);
    }
    useEffect(() => {
        
        
            getSummary();
       
      }, []);
      const refHolder = useRef(null);
      var ENGLISH = {};
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").forEach(function(ch) {
            ENGLISH[ch] = true;
        });
        function stringIsEnglish(str) {
          var index;
      
          for (index = str.length - 1; index >= 0; --index) {
              if (!ENGLISH[str.substring(index, index + 1)]) {
                  return false;
              }
          }
          return true;
      }
      async function checkRef(e=null){
        var refAddress = null;
      
        console.log(e);
        if(typeof e === "string"){
          refAddress = e;
        } else {
          if(e && (e instanceof Event)){
            console.log(e);
            refAddress = e;
          }
          else{
          const form = refHolder.current;
          refAddress = `${form['hsearch'].value}`;
          }
        }
          refHolder.current['hsearch'].value = refAddress;
          refAddress = refAddress.replace(/ /g,'');
          refAddress = refAddress.replace(/[^0-9a-z]/gi,'');
          const response = await fetch(validateUrl+refAddress);
          const jsonData = await response.json();
          const researchEp = process.env.REACT_APP_TREASURY_API+"v1/summary/research/"+refAddress;
          const rdfx = await fetch(researchEp);
          const researchData = await rdfx.json();
          console.log(researchData);
          setResan(researchData);
          try{
          const recentListDis = await (researchData['related']).map(function(k,i){
            if(stringIsEnglish(k.hashtag))
              return <li class="researchSuggestion"  onClick = { ()=> checkRef(k.hashtag)} key={i}>#{k.hashtag}</li>;
          });

          const variantListDis = await (researchData['variants']).map(function(k,i){
            if(stringIsEnglish(k.hashtag))
              return <li class="researchSuggestion variantSuggestion"  onClick = { ()=> checkRef(k.hashtag)} key={i}>#{k.hashtag}<span class="variant_usg">{k.perusage} %</span></li>;
          });
          setResearch(recentListDis);
          setVariants(variantListDis);
        }
        catch(e){
          setResearch(null);
          console.log(e);
        }
          
          setKwData(refAddress);
          setDisData(1);
          if(jsonData.error)
              {setErrData('Available');
              console.log("Available, trigger research mode");
              
            }
              
          else
              setErrData('Not available');
          
          
      }
     
      const getSummary = async () => {
          const response = await fetch(summaryUrl);
          const jsonData = await response.json();
          setSummaryData(jsonData);
          paginate(0);
          
         

        
      };
    return(
        <><div class="header text-center">
        <a class="logo" href="/">
                <img src="https://tagprotocol.s3.us-east-1.amazonaws.com/logo-white-sb.png" class="img-fluid" style={{"height":"40px"}}/>
            </a>
      </div>
      

    <div class="app-content hashapp-content">
    <div id="recentHashTags_MainContainer" class="marquee">
         <h4 style={{color:"#2e7bca", marginBottom:"1em", "textTransform":"uppercase"}}>Recently Registered</h4>
         <ul class="recentHashTags">{recentData}</ul>
         <ul class="recentHashTags">{recentData1}</ul>
         <ul class="recentHashTags">{recentData2}</ul>
         <div class="pagination_el"><button class="btn btn-primary" ref={refPurchase} onClick={() => paginate(curpage-30)}>&#60;</button>{ summaryData.hashtags && <span class="pageNo">Page {(curpage/30)+1} of {Math.ceil(summaryData.hashtags/30)}</span> }<button class="btn btn-primary" onClick={() => paginate(curpage+30)}>&#62;</button></div>
         <a href="/" class="btn btcBackRecents">Back</a>
     </div>
      </div>
      
			
		

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js" integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0" crossorigin="anonymous"></script>

    </>
    );
}
export default Recenttags;