import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import InfiniteScroll from "react-infinite-scroll-component";
import { store } from 'react-notifications-component';



const Web3 = require('web3'); 
const web3 = new Web3(process.env.REACT_APP_RPC); 

function Staking(){
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber()
    const [stakeData,setStakeDate] = useState({});
    const [count, setCount] = useState(0);
    const [statex, setStatex] = useState({hasMore:true, items:[]});
    const [page,setPage] = useState(1);
    const [maxPage,setMaxPage] = useState()
    const [claimFee,setClaimFee] = useState(0)
    const [activeClaim,setActiveClaim]=useState(1)
    const initialData = async() => {
      const mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+page;
      const response = await fetch(mytags);
      const jsonData = await response.json();
      
      var hasMore = false;
      setCount(jsonData['total']);
      if(parseInt(jsonData['totalPages'])>page){
        hasMore = true 
      }
      
      setStatex({
        items: jsonData['data'],
        hasMore: hasMore
      });
      setMaxPage(parseInt(jsonData['totalPages']))
    }
    const fetchMoreData = async () => {
        var hasMore = false;
        var retData = []
        var refAddress = false
        if((maxPage>1&&page!=maxPage+1) || refAddress ){
          var lpage = page+1
          setPage(lpage);
          hasMore = true;
          var mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+lpage;
          if(refAddress){
            lpage = 1;
            setPage(1);
            hasMore=false;
            mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+lpage+"/"+refAddress;
          }
          
          
          
          const response = await fetch(mytags);
          const jsonData = await response.json();
     
          retData = jsonData['data']
          setCount(jsonData['total']);
          if(parseInt(jsonData['totalPages'])>page){
            hasMore = true 
          }
          if(parseInt(jsonData['totalPages'])==page){
            hasMore = false 
          }
        }
        setStatex({items:statex.items.concat(retData), hasMore:hasMore})
        if(refAddress){
        setStatex({items:retData, hasMore:hasMore})
        }
        
      };
    let history = useHistory();
    
    useEffect(() => {
      loadData();
        initialData();
      }, []);
    async function loadData(){
        const StakeFetch = await fetch(process.env.REACT_APP_LEDGER_API+"v1/summary/getWallet/"+ls.get('acct'));
        const StakeRow = await StakeFetch.json();
        const stakeRes = StakeRow[0]
        var stakeData = {}
        
        stakeData['staked'] = 0
        stakeData['mined'] = 0
        stakeData['minted'] = 0
        stakeData['unminted'] = 0
        if(stakeRes) { 
            if(stakeRes.hasOwnProperty('staked')){
            stakeData['staked'] = stakeRes['staked']
            }
            if(stakeRes.hasOwnProperty('mined')){
            stakeData['mined'] = (stakeRes['mined']).toFixed(4)
            }
            if(stakeRes.hasOwnProperty('minted')){
            stakeData['minted'] = (stakeRes['minted']).toFixed(4)
            }
            if(stakeRes.hasOwnProperty('unminted')){
            stakeData['unminted'] = (stakeRes['unminted']).toFixed(4)
            }
        }
        //staked mined minted unminted
        setStakeDate(stakeData)

        //get claim status
      const ClaimFetch = await fetch(process.env.REACT_APP_LEDGER_API+"v1/summary/getClaims/"+ls.get('acct'));
      const ClaimRow = await ClaimFetch.json();
      const activeClaims = (ClaimRow['claims'])
      setActiveClaim(activeClaims)
      if(activeClaims>0){

      }
    }

    const claimAdd = process.env.REACT_APP_CLAIM_ADDR
    const claimAbi = require('./claimAbi.json')
    console.log(claimAdd)
    const web3x = new Web3(wallet.ethereum); 
    const claimInstance = new web3x.eth.Contract(claimAbi, claimAdd,{'from':ls.get('acct')});
    claimInstance.methods.fetchFees().call({},function(e,r){
      if(e != null) { return 0; }
      setClaimFee(r)
    })
    function claimMin(){
      try{
        const rate = claimFee
        //console.log(claimFee)
        const rateWei = Web3.utils.toWei('.0010001','ether');
        //console.log(rateWei)
       claimInstance.methods.claim().send({'from':ls.get('acct'),'value':rateWei}).on('transactionHash', function(hash){
           
         store.addNotification({
           id:"wallet-error",
           title: "Your claim request has been sent",
           message: hash,
           type: "info",
           insert: "top",
           container: "top-center",
           animationIn: ["animate__animated", "animate__fadeIn"],
           animationOut: ["animate__animated", "animate__fadeOut"],
           dismiss: {
             duration: 10000
           }
         });
         loadData()
       }).on('receipt', function(receipt){
         store.addNotification({
           id:"wallet-error",
           title: "Your claim request is succesful",
           message: "Please wait for your claim to be settled",
           type: "info",
           insert: "top",
           container: "top-center",
           animationIn: ["animate__animated", "animate__fadeIn"],
           animationOut: ["animate__animated", "animate__fadeOut"],
           dismiss: {
             duration: 10000
           }
         });
         loadData()
       })
      }
      catch(e){
        console.log(e)
        store.addNotification({
         id:"e-wallet-error",
         title: "Error",
         message: e.message,
         type: "danger",
         insert: "top",
         container: "top-center",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 5000
         }
       });
       
       loadData()
      }
       
      
      }
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
		<h3>Hashtag Staking</h3>
        <div class="d-flex justify-content-between mb-2">
				<div class="datasec">
					<h5>HTAG STAKED</h5>
					<span>{stakeData.staked} HTAG</span>
				</div>
				<div class="datasec">
					<h5>TAGCOIN MINED</h5>
					<span>{(stakeData.mined)} TAG</span>
				</div>
			  </div>

        <div class="d-flex justify-content-between mb-2">
        <div class="datasec">
					<h5>UNMINTED</h5>
					<span>{(stakeData.unminted)} TAG</span>
				</div>
				<div class="datasec">
					<h5>MINTED</h5>
					<span>{(stakeData.minted)} TAG</span>
				</div>
				
			  </div>
        <div class="d-flex justify-content-center mt-3">
        {activeClaim == 0 &&
        
        <a href="#" class="btnn" onClick={()=> claimMin()}>Mint &amp; Claim</a>
      
    }
    {activeClaim > 0 &&
      
      <a href="#" class="btnn" onClick={()=> history.push('/claimStat')}>Track Your Claim</a>
    
  }
		</div>

        <div class="myhashtags mt-3">
        <InfiniteScroll
          dataLength={statex.items.length}
          next={fetchMoreData}
          hasMore={statex.hasMore}
          loader={<span class="loadMore">Scroll to load more</span>}
          
          endMessage={
            <p style={{ textAlign: "center" }}>
              
            </p>
          }
        ><ul class="myhashtags">
          {statex.items.map((i, index) => (
            <li key={index} onClick={() => {history.push('/taginfo/'+i._id); }}><span>#{i.hashtag}<label>NFT ID : {i._id}</label></span></li>
          ))}</ul>
        </InfiniteScroll>
		</div>

		<div class="app-btnsec">
			<a href="/dashboard" class="btn mr-2">Back</a>
		</div>
	</div>
      
    
     

   

    </>
    );
}
export default Staking;