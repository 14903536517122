import React from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import abiVal from '../data/abi.json';
const Web3 = require('web3'); 
const web3 = new Web3(abiVal.w3api); 

function SelectTrade(){
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber();
    let history = useHistory();
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
		
		<ul class="dl-lists">
			<li><a onClick={() => {history.push('/tradePancake'); }} ><img src="https://tagprotocol.s3.us-east-1.amazonaws.com/PANCAKESWAP.jpg" alt="tradePC"/></a></li>
			<li><a onClick={() => {history.push('/tradeSushi'); }}><img src="https://tagprotocol.s3.us-east-1.amazonaws.com/SUSSHISWAP.jpg" alt="tradeSu"/></a></li>		
			
		</ul>
		<div class="app-footer bg-white pt-0">
			<h4 class="mb-0" style={{display:"none"}}>TAGCOIN Balance</h4>
			<div class="balancesec" style={{display:"none"}}>
				<span style={{color:"#1e82ce"}}>0.0000 TAG</span> |
				<span style={{color:"#d49816"}}>0.0000 USD</span>
			</div>
		</div>
		<div class="app-btnsec">
			<a href="/dashboard" class="btn mr-2">Back</a>
		</div>
	</div>
 
    </>
    );
}
export default SelectTrade;