import React from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
const Web3 = require('web3'); 
const web3 = new Web3(process.env.REACT_APP_RPC); 

function Trade(){
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber()
    let history = useHistory();
   
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
		<h3>HASHTAG AUCTIONS</h3>
		<p>This service allows users to list & receive bids on their hashtag tokens. Users can also send bids on other users listed hashtags..</p>
		<p style={{textAlign:"center"}}> Launches after<br/><strong>The sale of 100,000 HASHTAGS</strong></p>
		
		<div class="app-btnsec">
			<a href="/dashboard" class="btn mr-2">Back</a>
		</div>
	</div>
      
    
     

   

    </>
    );
}
export default Trade;