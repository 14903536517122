import React, { useState,useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import Loader from '../components/Loader';
import { store } from 'react-notifications-component';
import BackButton from '../components/sharedBackButton';
import ls, { set } from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import abiVal from '../data/abi.json';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Web3 = require('web3'); 
const web3 = new Web3('https://bsc-dataseed4.binance.org/'); 
const CONTRACT_ADDRESS = abiVal.pswapcontract;
const CONTRACT_ABI = abiVal.pswapabi;

const TAG_ADDRESS = abiVal.tagcontract;
const TAG_ABI = abiVal.tagabi;

const BUSD_ADDRESS = abiVal.firsttokencontract;
const BUSD_ABI = abiVal.firsttokenabi;

const PAIR_ADDRESS = abiVal.paircontract;
const PAIR_ABI = abiVal.pairabi;

const PAIRTWO_ADDRESS = abiVal.pairtwocontract;
const PAIRTWO_ABI = abiVal.pairtwoabi;

function LiquidityPan(){
    const wallet = useWallet();
    let history = useHistory();
    const webb3 =  new Web3(wallet.ethereum);
    const contractInstance = new webb3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    const tagcontractInstance = new webb3.eth.Contract(TAG_ABI, TAG_ADDRESS);
    const pairInstance = new webb3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
    const pairtwoInstance = new webb3.eth.Contract(PAIRTWO_ABI, PAIRTWO_ADDRESS);

    const [tokname, setTokname] = useState('BNB');
    const [from, setFrom] = useState('BNB');
    const [to, setTo] = useState('TAG');
    const [fromval, setFromval] = useState('');
    const [toval, setToval] = useState('');
    const [tagbalance, setTagbal] = useState('');
    const [tokbalance, setTokbal] = useState('');
    const [pairbalance, setPairbal] = useState('');
    const [pairval, setPairval] = useState('');
    const [bnbprice, setBnbprice] = useState('');
    const [loadinggadd,setLoadinggadd] = useState(false);
    const [loadinggaptok,setLoadinggaptok] = useState(false);
    const [loadinggaptag,setLoadinggaptag] = useState(false);
    const [loadinggrem,setLoadinggrem] = useState(false);
    const [tokenapp,setTokenapp] = useState(true); //token approve button enabled
    const [tagapp,setTagapp] = useState(true); //tag approve button enabled
    const [activecontract,setActiveContract] = useState('')
    const [cpy,setCpy] = useState(false)
    

    useEffect(() => {
        getTokbal();
    }, []);
        
function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  //to not round values while showing balance
function noround(val,x){
  var float= parseFloat(val).toFixed(18);
  var num = float.toString(); 
  var n = num.slice(0, (num.indexOf("."))+(x+1)); 
  return (n);
}

//sets value for token balance and lp token balance
    const getTokbal = async () => {
        var name = localStorage.getItem('Tokname');
        if(name)
        setTokname(name);
        else
        {
          localStorage.setItem('Tokname','BNB');
          var name = 'BNB';
        }
        const w3 =  new Web3('https://bsc-dataseed4.binance.org/');
        const tagcontractInstanceto = new w3.eth.Contract(TAG_ABI, TAG_ADDRESS);
        const busdcontractInstanceto = new w3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);


        if(name=="BNB")
        {
          setTokbal('BNBval');
        }
        else
        if(name=="BUSD"){
        if(tokbalance!= ''){ await timeout(8000); }
        await busdcontractInstanceto.methods.balanceOf(ls.get('acct')).call();
        await busdcontractInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
          if (error != null) {
              console.log(error)
              return;
          }
          else
          {
          setTokbal(res);}
          })
        }

        if(tagbalance!= ''){ await timeout(8000); }
        if(name=="BNB"){
            setFrom("BNB");
            setActiveContract(PAIR_ADDRESS)
            const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
        await pairInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
            if (error != null) {
                console.log(error)
                return;
            }
            else
            {console.log("tokbal - ",res);
            setPairbal(res);}
        })}
        else if(name=="BUSD"){
            setFrom("BUSD");
            setActiveContract(PAIRTWO_ADDRESS)
            const pairtwoInstanceto = new w3.eth.Contract(PAIRTWO_ABI, PAIRTWO_ADDRESS);
            await pairtwoInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
                if (error != null) {
                    console.log(error)
                    return;
                }
                else
                {console.log("tokbal - ",res);
                setPairbal(res);}
            })}
        await tagcontractInstanceto.methods.balanceOf(ls.get('acct')).call({}, function(error, res) {
            if (error != null) {
                console.log(error)
                return;
            }
            else
            {console.log("tagbal - ",res);
            setTagbal(res);}
        })
       };


    async function handlepairChange(val) {
        setPairval(val);
    }

//autocomplete 1st input value
async function handlefromChange(val) {
    setFromval(val);
    var name = localStorage.getItem('Tokname');
    const tagadd = TAG_ADDRESS;
    if(name=="BNB")
    {
    var pairInst = pairInstance;
  }
    else if(name=="BUSD")
    {
    var pairInst = pairtwoInstance;
  }

    if(isNaN(val) || val == '')
    {
        setFromval('');
        setToval('');
    }
    else{
      if(name=="BNB"){
      await pairInst.methods.getReserves().call(function (err, res) {
        if (err) {
          console.log("An error occured", err)
          return
        }
        console.log(res,"resssss");
        setToval(parseFloat((webb3.utils.fromWei(res[0])/webb3.utils.fromWei(res[1]))*val).toFixed(12));
      })
    }
      if(name=="BUSD"){
      await pairInst.methods.getReserves().call(function (err, res) {
        if (err) {
          console.log("An error occured", err)
          return
        }
        console.log(res,"resssss");
        setToval(parseFloat((webb3.utils.fromWei(res[0])/webb3.utils.fromWei(res[1]))*val).toFixed(12));
      })
    }
  }
}

//autocompletes From input field based on value on To field
async function handletoChange(val) {
    setToval(val);
    var name = localStorage.getItem('Tokname');
    const tagadd = TAG_ADDRESS;
    if(name=="BNB")
    {
    var pairInst = pairInstance;
  }
    else if(name=="BUSD")
    {
    var pairInst = pairtwoInstance;
  }

    if(isNaN(val) || val == '')
    {
        setFromval('');
        setToval('');
    }
    else
    {
      if(name=="BNB"){
      await pairInst.methods.getReserves().call(function (err, res) {
        if (err) {
          console.log("An error occured", err)
          return
        }
        console.log(res,"resssss");
        setFromval(parseFloat((webb3.utils.fromWei(res[1])/webb3.utils.fromWei(res[0]))*val).toFixed(12));
      })
    }
      if(name=="BUSD"){
      await pairInst.methods.getReserves().call(function (err, res) {
        if (err) {
          console.log("An error occured", err)
          return
        }
        console.log(res,"resssss");
        setFromval(parseFloat((webb3.utils.fromWei(res[1])/webb3.utils.fromWei(res[0]))*val).toFixed(12));
      })
    }
    }
  }

 
  //To approve Token
  async function approveToken(){
    if(fromval!='' && toval!=''){
      setLoadinggaptok(true);
    var name = localStorage.getItem('Tokname');
    let gaslim = await webb3.eth.estimateGas({from:wallet.account});
    let gaspri = await webb3.eth.getGasPrice();
    let fromconaddr = CONTRACT_ADDRESS;
    if(name=="BUSD")
    {
    var tokencontractInstance = new webb3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
    }
    await tokencontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(fromval)).send(
      {
          from:wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri
      }).on('transactionHash',async function(hash){
        
      setTokenapp(false);
      setLoadinggaptok(false);

      
      store.addNotification({
        id:"approvee-complete",
        title: "Approved",
        message: "Transaction was approved",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 5000
        }
      })
      }).on('error', function(error, receipt) {
        console.log('error');
        setLoadinggadd(false);
        store.addNotification({
        id:"approvee-error",
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 8000
        }
        });
    });
  }
  }

//To approve Tag
  async function approveTag(){
    if(fromval!='' && toval!=''){
      setLoadinggaptag(true);
    let gaslim = await webb3.eth.estimateGas({from:wallet.account});
    let gaspri = await webb3.eth.getGasPrice();
    let fromconaddr = CONTRACT_ADDRESS;
    await tagcontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(toval)).send(
      {
          from:wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri
      }).on('transactionHash',async function(hash){
        setTagapp(false);
        setLoadinggaptag(false);

        store.addNotification({
          id:"approvee-complete",
          title: "Approved",
          message: "Transaction was approved",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
          duration: 5000
          }
        })
        }).on('error', function(error, receipt) {
          console.log('error');
          setLoadinggadd(false);
          store.addNotification({
          id:"approvee-error",
          title: "Error",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 8000
          }
          });
      });
  }
  }


//to add liquidity
    async function addliqToken() {
        setLoadinggadd(true);
        var name = localStorage.getItem('Tokname');
        let endtime = (Math.floor(Date.now()/1000)+300);
        let gaslim = await webb3.eth.estimateGas({from:wallet.account});
        let gaspri = await webb3.eth.getGasPrice();
        console.log(gaslim);
        const tagadd = TAG_ADDRESS;
        if(name=="BUSD")
        {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        }
         var mintag = (parseFloat(0.95*toval).toFixed(16)).toString();
         var mintok = (parseFloat(0.95*fromval).toFixed(16)).toString();

        let fromconaddr = CONTRACT_ADDRESS;
        if(name=="BNB")
        {           //handles BNB-TAG liquidity pair
        await tagcontractInstance.methods.approve(fromconaddr,webb3.utils.toWei(toval)).send(
            {
                from:wallet.account,
                gasLimit: gaslim,
                gasPrice: gaspri
            }
            ).on('transactionHash',async function(hash){
                            console.log("Approved");
                            
                            store.addNotification({
                                id:"approvee-complete",
                                title: "Approved",
                                message: "Transaction was approved",
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                duration: 5000
                                }
                            });
                            await contractInstance.methods.addLiquidityETH(
                            tagadd,
                            (webb3.utils.toWei(toval)).toString(),
                            webb3.utils.toWei(mintag,'ether'),
                            webb3.utils.toWei(mintok,'ether'),
                            wallet.account,
                            endtime).send(
                            {
                                from:wallet.account,
                                gasLimit: 500000,
                                gasPrice: webb3.utils.toWei("10", "gwei"),
                                value: webb3.utils.toWei(fromval)
                            }
                            ).on('transactionHash',async function(hashh){
                                console.log("Liquidity added");
                                await getTokbal();
                                setLoadinggadd(false);
                                
                                    store.addNotification({
                                        id:"addliq-complete",
                                        title: "SUCCESS",
                                        message: "Liquidity was added successfully",
                                        type: "success",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                        duration: 5000
                                        }
                                    });
                            }).on('error', function(error, hashh) {
                                console.log('error');
                                setLoadinggadd(false);
                                store.addNotification({
                                  id:"addliq-error",
                                  title: "Error",
                                  message: error.message,
                                  type: "danger",
                                  insert: "top",
                                  container: "top-center",
                                  animationIn: ["animate__animated", "animate__fadeIn"],
                                  animationOut: ["animate__animated", "animate__fadeOut"],
                                  dismiss: {
                                    duration: 8000
                                  }
                                });
                            });
          }).on('error', function(error, receipt) {
            console.log('error');
            setLoadinggadd(false);
            store.addNotification({
              id:"approvee-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000
              }
            });
          });
        }
        else                //handles all other liquidity pairs except BNB
        {
               await contractInstance.methods.addLiquidity(
                            tagadd,
                            tokencaddress,
                            (webb3.utils.toWei(toval)).toString(),
                            (webb3.utils.toWei(fromval)).toString(),
                            webb3.utils.toWei(mintag,'ether'),
                            webb3.utils.toWei(mintok,'ether'),
                            wallet.account,
                            endtime).send(
                            {
                                from:wallet.account,
                                gasLimit: 500000,
                                gasPrice: webb3.utils.toWei("10", "gwei")
                            }
                            ).on('transactionHash',async function(hashh){
                                console.log("Liquidity added");
                                await getTokbal();
                                setLoadinggadd(false);
                                setTokenapp(true);
                                setTagapp(true);
                                
                                    store.addNotification({
                                        id:"addliq-complete",
                                        title: "SUCCESS",
                                        message: "Liquidity was added successfully",
                                        type: "success",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                        duration: 5000
                                        }
                                    });
                            }).on('error', function(error, hashh) {
                                console.log('error');
                                setLoadinggadd(false);
                                store.addNotification({
                                  id:"addliq-error",
                                  title: "Error",
                                  message: error.message,
                                  type: "danger",
                                  insert: "top",
                                  container: "top-center",
                                  animationIn: ["animate__animated", "animate__fadeIn"],
                                  animationOut: ["animate__animated", "animate__fadeOut"],
                                  dismiss: {
                                    duration: 8000
                                  }
                                });
                            });
        }
    }

//To remove liquidity
    async function remliqToken() {
        setLoadinggrem(true);
        let endtime = (Math.floor(Date.now()/1000)+600);
        let gaslim = await webb3.eth.estimateGas({from:wallet.account});
        let gaspri = await webb3.eth.getGasPrice();
        console.log(gaslim);
        let fromconaddr = CONTRACT_ADDRESS;
        const tagadd = TAG_ADDRESS;
        var name = localStorage.getItem('Tokname');

        if(name=="BUSD")
        {var tokencaddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        var pairInstanceto = pairtwoInstance;
        }
        
        if(name=="BNB")
        {
        await pairInstance.methods.approve(fromconaddr,webb3.utils.toWei(pairval)).send(
            {
                from:wallet.account,
                gasLimit: gaslim,
                gasPrice: gaspri
            }
            ).on('transactionHash',async function(hash){
                        store.addNotification({
                            id:"approve-complete",
                            title: "Approved",
                            message: "Transaction was approved",
                            type: "success",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 5000
                            }
                        });
                        await contractInstance.methods.removeLiquidityETH(
                                tagadd,
                                (webb3.utils.toWei(pairval)).toString(),
                                0,
                                0,
                                wallet.account,
                                endtime).send(
                                {
                                    from:wallet.account,
                                    gasLimit: 500000,
                                    gasPrice: webb3.utils.toWei("10", "gwei")
                                }
                                ).on('transactionHash',async function(hash){
                                    console.log(hash);
                                    await getTokbal();
                                    setLoadinggrem(false);
                                    store.addNotification({
                                        id:"remliq-complete",
                                        title: "REMOVED",
                                        message: "Liquidity was removed successfully",
                                        type: "success",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                        duration: 5000
                                        }
                                    });
                                }).on('error', function(error, hash) {
                                    console.log('error');
                                    setLoadinggrem(false);
                                    store.addNotification({
                                      id:"remliq-error",
                                      title: "Error",
                                      message: error.message,
                                      type: "danger",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 8000
                                      }
                                    });
                                });
              }).on('error', function(error, hash) {
                console.log('error');
                setLoadinggrem(false);
                store.addNotification({
                  id:"approve-error",
                  title: "Error",
                  message: error.message,
                  type: "danger",
                  insert: "top",
                  container: "top-center",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 8000
                  }
                });
              });
        }
        else
        {
        await pairInstanceto.methods.approve(fromconaddr,webb3.utils.toWei(pairval)).send(
            {
                from:wallet.account,
                gasLimit: gaslim,
                gasPrice: gaspri
            }
            ).on('transactionHash',async function(hash){
                        store.addNotification({
                            id:"approve-complete",
                            title: "Approved",
                            message: "Transaction was approved",
                            type: "success",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 5000
                            }
                        });
                        await contractInstance.methods.removeLiquidity(
                                tagadd,
                                tokencaddress,
                                (webb3.utils.toWei(pairval)).toString(),
                                0,
                                0,
                                wallet.account,
                                endtime).send(
                                {
                                    from:wallet.account,
                                    gasLimit: 500000,
                                    gasPrice: webb3.utils.toWei("10", "gwei")
                                }
                                ).on('transactionHash',async function(hash){
                                    console.log(hash);
                                    await getTokbal();
                                    setLoadinggrem(false);
                                    store.addNotification({
                                        id:"remliq-complete",
                                        title: "REMOVED",
                                        message: "Liquidity was removed successfully",
                                        type: "success",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                        duration: 5000
                                        }
                                    });
                                }).on('error', function(error, hash) {
                                    console.log('error');
                                    setLoadinggrem(false);
                                    store.addNotification({
                                      id:"remliq-error",
                                      title: "Error",
                                      message: error.message,
                                      type: "danger",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 8000
                                      }
                                    });
                                });
              }).on('error', function(error, hash) {
                console.log('error');
                setLoadinggrem(false);
                store.addNotification({
                  id:"approve-error",
                  title: "Error",
                  message: error.message,
                  type: "danger",
                  insert: "top",
                  container: "top-center",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 8000
                  }
                });
              });
        }
    }
    
    function handleMaxfrom(f,bnb,tok,tag){
      console.log("handlemax");
      if(f=="BNB")
      {
        var x =noround(parseFloat(web3.utils.fromWei(bnb), 'ether'),8);
        console.log("x - ",x);
        setFromval(x);
        handlefromChange(x);
      }
      else if(f=="TAG")
      {
        var x =noround(parseFloat(web3.utils.fromWei(tag), 'ether'),8);
        console.log("x - ",x);
        setFromval(x);
        handlefromChange(x);
      }
      else
      {
        var x =noround(parseFloat(web3.utils.fromWei(tok), 'ether'),8);
        console.log("x - ",x);
        setFromval(x);
        handlefromChange(x);
      }
    }

    function handleMaxto(f,bnb,tok,tag){
      console.log("handlemax");
      if(f=="BNB")
      {
        var x =noround(parseFloat(web3.utils.fromWei(bnb), 'ether'),8);
        console.log("x - ",x);
        setToval(x);
        handletoChange(x);
      }
      else if(f=="TAG")
      {
        var x =noround(parseFloat(web3.utils.fromWei(tag), 'ether'),8);
        console.log("x - ",x);
        setToval(x);
        handletoChange(x);
      }
      else
      {
        var x =noround(parseFloat(web3.utils.fromWei(tok), 'ether'),8);
        console.log("x - ",x);
        setToval(x);
        handletoChange(x);
      }
    }

    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div className="app-content trcontent">
        		
   <div className="tagtrade-content">
		<h3>Trade Tagcoin</h3>
        
			<div className="tagtrade">
		<ul className="nav nav-tabs">
			<li className=""><a href="" onClick={() => localStorage.setItem('Tokname', 'BNB')} className={tokname==="BNB"? "active show tokbutton" : "tokbutton"} style={{float:"right"}}>TAG/BNB</a></li>
			<li><a href="" onClick={() => localStorage.setItem('Tokname', 'BUSD')} className={tokname==="BUSD"? "active show tokbutton" : "tokbutton"} >TAG/BUSD</a></li>
		 </ul>
			</div>
		<ul className="nav nav-tabs">
			<li className=""><a href="" onClick={() => {history.push('/tradePancake'); }} >SWAP</a></li>
			<li><a href="" className="active show">ADD LIQUIDITY</a></li>
		 </ul>
    </div> 
<div className={ tokname === "BNB" ? "body bnbliqbody" : "body tokenliqbody"} >
    
    <div className="form-container">
        <div className="field-container" style={{background: "#c9d1d8",padding: "12px"}}>
            <label className="labl" htmlFor="name" style={{padding: "5px"}}>Input</label>
            <label className="labl" htmlFor="bal" style={{padding: "5px",float:"right"}}>Balance : {noround(parseFloat(web3.utils.fromWei(tokbalance==="BNBval"? wallet.balance : tokbalance), 'ether'),4)}</label>
            <div style={{display:"flex"}}>
            <input id="bnb" className="inp" maxLength="10" type="text" name="fromvalue" style={{background: "transparent",border: "0px",outline:"none"}} placeholder="0.0" onChange={e => handlefromChange(e.target.value)} value={fromval}/>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",fontWeight:"700",color:"#1e82ce",cursor:"pointer",outline:"0px"}} onClick={e=>handleMaxfrom(from,wallet.balance,tokbalance,tagbalance)}>MAX</button>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",outline:"0px"}}>{from}</button>
            </div>
        </div>
        <div className="downarroww" style={{cursor: "unset"}}>
        <svg fill="#000000"  viewBox="0 0 24 24" width="14px" height="14px"><path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>
        </div>
        <div className="field-container" style={{background: "#c9d1d8",padding: "12px",marginTop:"3%"}}>
            <label className="labl" htmlFor="name" style={{padding: "5px"}}>Input</label>
            <label className="labl" htmlFor="bal" style={{padding: "5px",float:"right"}}>Balance : {noround(parseFloat(web3.utils.fromWei(tagbalance), 'ether'),4)}</label>
            <div style={{display:"flex"}}>
            <input id="tag" className="inp" maxLength="20" type="text" name="tovalue" style={{background: "transparent",border: "0px",outline:"none"}} placeholder="0.0" onChange={e =>handletoChange(e.target.value)} value={toval}/>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",fontWeight:"700",color:"#1e82ce",cursor:"pointer",outline:"0px"}} onClick={e=>handleMaxto(to,wallet.balance,tokbalance,tagbalance)}>MAX</button>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",outline:"0px"}}>{to}</button>
            </div>
        </div>
        {tokname!="BNB" &&
        <div className="form-container" style={{gridColumn:"1/3"}}>
        <div className="field-container trloadsm" style={{marginTop:"7px",gridColumn: "1",marginRight:"3%",borderRadius: "5px"}}>
            <button className={ loadinggaptok === true ? "liqbutton" : "liqbutton disnone"} onClick={approveToken} disabled={!tokenapp}>ENABLE {tokname}<Loader/></button>
        </div>
        <div className="field-container trloadsm" style={{marginTop:"7px",gridColumn: "2/3",gridRow:"1",marginLeft:"3%",borderRadius: "5px"}}>
            <button className={ loadinggaptag === true ? "liqbutton" : "liqbutton disnone"} onClick={approveTag} disabled={!tagapp}>ENABLE TAG<Loader/></button>
        </div>
        <div className="field-container trload" style={{marginTop:"3%",gridColumn: "1/3",borderRadius: "5px"}}>
            <button className={ loadinggadd === true ? "liqbutton" : "liqbutton disnone"} onClick={addliqToken} disabled={tokenapp||tagapp}>ADD LIQUIDITY<Loader/></button>
        </div>
        </div>
        }
        {tokname=="BNB" &&
        <div className="field-container trload" style={{marginTop:"3%",gridColumn: "1/3",borderRadius: "5px"}}>
            <button className={ loadinggadd === true ? "liqbutton" : "liqbutton disnone"} onClick={addliqToken}>ADD LIQUIDITY<Loader/></button>
        </div>
        }
    </div>
    <ul className="accinfo-list pb-2" style={{marginTop:"20px",marginBottom:"12px"}}>
				<li><label>TAG/{tokname} LPT </label><span>{noround(parseFloat(web3.utils.fromWei(pairbalance), 'ether'),4)} LPT</span></li>
				<li><label>{tokname} BALANCE</label><span>{noround(parseFloat(web3.utils.fromWei(tokbalance==="BNBval"? wallet.balance : tokbalance), 'ether'),4)} {tokname}</span></li>
				<li><label>TAG BALANCE</label><span>{noround(parseFloat(web3.utils.fromWei(tagbalance), 'ether'),4)} TAG</span></li>
			</ul>
    
    <div className="form-container">
        <div className="field-container" style={{background: "#c9d1d8",padding: "12px"}}>
            <label className="labl" htmlFor="name" style={{padding: "5px"}}>Input</label>
            <label className="labl" htmlFor="bal" style={{padding: "5px",float:"right"}}>Balance : {noround(parseFloat(web3.utils.fromWei(pairbalance), 'ether'),4)}</label>
            <div style={{display:"flex"}}>
            <input id="bnb" className="inp" maxLength="10" type="text" name="fromvalue" style={{background: "transparent",border: "0px",outline:"none"}} placeholder="0.0" onChange={e => handlepairChange(e.target.value)} value={pairval}/>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",fontWeight:"700",color:"#1e82ce",cursor:"pointer",outline:"0px"}} onClick={e=>setPairval(noround(parseFloat(web3.utils.fromWei(pairbalance), 'ether'),8))}>MAX</button>
            <button style={{display: "inline-flex",background: "transparent",border: "0px",height: "16px",marginTop: "7px",outline:"0px"}}>LPT</button>
            </div>
        </div>
        <div className="field-container trload" style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}>
            <button className={ loadinggrem === true ? "liqbutton" : "liqbutton disnone"} onClick={remliqToken}>REMOVE LIQUIDITY<Loader/></button>
        </div>
        
        <div className="field-container trload" style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}>
           
           <center><a href={"https://pancakeswap.finance/info/pool/"+activecontract} target="_blank" style={{marginTop:"10px",padding:"10px","display":"block"}}>Check Pool Stats on Pancakeswap</a></center> 
        <center>
        <p style={{fontSize:"12px"}}>Tagcoin Address <br/> 0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5</p>
        <CopyToClipboard text="0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5" onCopy={() => setCpy(true)}>{cpy ? <button class="copyTagCoinAdd">Copied !</button> : <button class="copyTagCoinAdd copied">Copy Tagcoin Address</button>}</CopyToClipboard>
        </center>
        </div>

        <div style={{marginTop:"5%",gridColumn: "1/3",borderRadius: "5px"}}><center><img src="https://tagprotocol.s3.us-east-1.amazonaws.com/POWERED%20BY%20PANCAKESWAP.png" alt="" style={{"margin":"auto"}}/></center></div>
    </div>

    </div>
    <div className="app-btnsec">
			<a href="/selectTrade" className="btn mr-2">Back</a>
		</div>
	</div>
      
    </>
    );
}
export default LiquidityPan;