import React from "react";
import { useHistory } from "react-router-dom";
import { useWallet } from 'use-wallet';
import ls from 'local-storage';


function BackButton(){
  
    let history = useHistory();
    const wallet = useWallet()
   
 
    function checkRef(e){
        e.preventDefault();
        history.goBack();
        
    }
    return(
        <>
            <div class="header text-center">
		<a class="logo" href="/">
            <img src="https://tagprotocol.s3.us-east-1.amazonaws.com/logo-white-sb.png" class="img-fluid" style={{"height":"40px"}}/>
        </a>
	</div>
             
      
      {ls.get('addr') ? (
      <div class="connStatus">Connected to {ls.get('acct')}</div>
      ) : (
        <div class="connStatus">Not Connected</div>
      )}
             
            


    </>
    );
}
export default BackButton;