import React,{ useState, useEffect, useRef } from "react";
import { useWallet } from "use-wallet";
import { useHistory } from "react-router-dom";
import BackButton from "../components/sharedBackButton";
import AppFooter from "../components/Appfooter";
import LoginCheck from '../components/LoginCheck'; //<LoginCheck/>
import ls from 'local-storage';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";


function Myhashtag(){
  const refHolder = useRef(null);
    const wallet = useWallet()
    const [stateData, setStateData] = useState();
    const [count, setCount] = useState(0);
    const [statex, setStatex] = useState({hasMore:true, items:[]});
    const [page,setPage] = useState(1);
    const [maxPage,setMaxPage] = useState()
    const initialData = async() => {
      const mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+page;
      const response = await fetch(mytags);
      const jsonData = await response.json();
      
      var hasMore = false;
      setCount(jsonData['total']);
      if(parseInt(jsonData['totalPages'])>page){
        hasMore = true 
      }
      
      setStatex({
        items: jsonData['data'],
        hasMore: hasMore
      });
      setMaxPage(parseInt(jsonData['totalPages']))
    }
    //
    
    let history = useHistory();
    const [summaryData, setSummaryData] = useState([]);
    
   
    useEffect(() => {
      getSummary();
      
      initialData();
    }, []);
    const fetchMoreData = async () => {
      var hasMore = false;
      var retData = []
      const form = refHolder.current;
      var refAddress = `${form['hsearch'].value}`;
      refAddress = refAddress.replace(/ /g,'');
      refAddress = refAddress.replace(/[^0-9a-z]/gi,'');
      console.log(refAddress);
      if((maxPage>1&&page!=maxPage+1) || refAddress ){
        var lpage = page+1
        setPage(lpage);
        hasMore = true;
        var mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+lpage;
        if(refAddress){
          lpage = 1;
          setPage(1);
          hasMore=false;
          mytags = process.env.REACT_APP_TREASURY_API+"v1/summary/mytokens/"+ls.get('acct')+"/"+lpage+"/"+refAddress;
        }
        
        
        
        const response = await fetch(mytags);
        const jsonData = await response.json();
   
        retData = jsonData['data']
        setCount(jsonData['total']);
        if(parseInt(jsonData['totalPages'])>page){
          hasMore = true 
        }
        if(parseInt(jsonData['totalPages'])==page){
          hasMore = false 
        }
      }
      setStatex({items:statex.items.concat(retData), hasMore:hasMore})
      if(refAddress){
      setStatex({items:retData, hasMore:hasMore})
      }
      
    };
    const getSummary = async () => {
      setStateData("Copy Address")
      const walletId = ls.get('acct');
      if(!walletId) {
        history.push('/wallet');
      }
      
    

    
    /*const recentListDis = await jsonData.map(function(k,i){


      return <li key={i}><span>#{k.hashtag}<label>NFT ID : {k.tokenId}</label></span><a href="#"></a></li>;
      
    });
    console.log(recentListDis); */
    setSummaryData({});
    };

    
    return(
        <>
         <LoginCheck/>
     <BackButton/>
  	<div class="app-content">
		<h3>My Hashtags ( {count} )</h3>
		
		<form class="reghashtag" ref={refHolder}>
			<div class="form-group d-flex searchsec">
				<input type="text" class="form-control" name = "hsearch" id=""/>
				<input type="button" class="form-control" value="Search" onClick={fetchMoreData}/>
			</div>
		</form>
		
		{parseInt(count)==0 ? (<><span>You dont have any hashtags, purchase one now to see it here.</span><a class="mt-4 btn innerBtn"  onClick={() => {history.push('/Research'); }}>Register Hashtag</a></>):(<></>)}
			
      <InfiniteScroll
          dataLength={statex.items.length}
          next={fetchMoreData}
          hasMore={statex.hasMore}
          loader={<span class="loadMore">Scroll to load more</span>}
          
          endMessage={
            <p style={{ textAlign: "center" }}>
              
            </p>
          }
        ><ul class="myhashtags">
          {statex.items.map((i, index) => (
            <li key={index} ><span class="icon_box" onClick={() => {history.push('/taginfo/'+i._id); }}><i class="fas fa-info"></i></span><span>#{i.hashtag}<label>NFT ID : {i._id}</label></span><span class="icon_box" onClick={() => {history.push('/transfer/'+i._id); }}><i class="fas fa-paper-plane"></i></span></li>
          ))}</ul>
        </InfiniteScroll>
		
		
		<div class="regcontractadd">
			<h4>HTAG REGISTRY CONTRACT ADDRESS</h4>
			<p><a class="simpleLink" href={'https://bscscan.com/token/'+process.env.REACT_APP_NFT_ADDR} target="_blank">{process.env.REACT_APP_NFT_ADDR}</a></p>
			<div class="d-flex justify-content-center">
      <CopyToClipboard text={process.env.REACT_APP_NFT_ADDR} onCopy={()=> setStateData("Copied Address")}><a href="#" class="mr-2">COPY ADDRESS</a></CopyToClipboard>
				
			</div>
		</div>

		 
		
		<div class="app-btnsec">
			<a href="/dashboard" class="btn">Back</a>
		</div>
	</div>
	
 

    </>
    );
}
export default Myhashtag;