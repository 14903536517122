import React from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
const Web3 = require('web3'); 
const web3 = new Web3(process.env.REACT_APP_RPC); 

function Dashboard(){
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber()
    let history = useHistory();
    function logout(){
        wallet.reset();
        ls.remove('addr')
        ls.remove('acct');
        ls.remove('claimed');
        ls.remove('walletconnect');
		ls.remove('ref');
		ls.remove('lev');
		ls.remove('pendingClaim');
		ls.remove('pendingClaim1');
        history.push('/wallet');
    }
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
		<h3>Dashboard</h3>
		<ul class="dl-lists">
			<li><a onClick={() => {history.push('/account'); }} >ACCOUNT INFORMATION</a></li>
			<li><a onClick={() => {history.push('/Research'); }}>Register HASHTAG</a></li>			
			<li><a onClick={() => {history.push('/myhashtags'); }}>MY HASHTAGS</a></li>
			<li><a  onClick={() => {history.push('/staking'); }}>HASHTAG STAKING</a></li>
			<li><a  href="https://tagcoin.io/" target="_blank">TRADE TAGCOIN</a></li>			
			<li><a  onClick={() => {history.push('/auction'); }}>hashtag marketplace</a></li>		
			<li><a   onClick={() => {history.push('/refearn'); }}>INVITE &amp; EARN</a></li>
			
		</ul>
		<div class="app-footer bg-white pt-0">
			<h4 class="mb-0" style={{display:"none"}}>TAGCOIN Balance</h4>
			<div class="balancesec" style={{display:"none"}}>
				<span style={{color:"#1e82ce"}}>0.0000 TAG</span> |
				<span style={{color:"#d49816"}}>0.0000 USD</span>
			</div>
		</div>
		<div class="app-btnsec">
			<a href="/wallet" class="btn mr-2">Back</a><a href="#" onClick={logout} class="btn">Disconnect</a>
		</div>
	</div>
      
    
     

   

    </>
    );
}
export default Dashboard;