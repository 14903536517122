import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import BackButton from "../components/sharedBackButton";
import AppFooter from "../components/Appfooter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoginCheck from "../components/LoginCheck";
import Loader from "../components/Loader";
import { BitlyClient } from "bitly";
import ls from "local-storage";
import QRCode from "react-qr-code";

const bitly = new BitlyClient("8aaff7125aad69262c04e08a229a40dbf1ebf650", {});

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const validateUrl = process.env.REACT_APP_TREASURY_API + "v1/summary/validate/";
function Refearn() {
  useEffect(() => {
    levelfetch();
  }, []);
  const [recentData, setRecentData] = useState([]);
  const [recentData2, setRecentData2] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [shorturl, setShorturl] = useState("");
  const [isValid, setIsValid] = useState(0);
  let history = useHistory();
  let shortify = "";

  async function levelfetch() {
    const isValidRef = await checkRef();
    if (isValidRef) {
      const levdata = await fetch(
        process.env.REACT_APP_TREASURY_API + "v1/summary/levels/all"
      );
      const levrows = await levdata.json();
      shortify = await bitly.shorten(
        "https://tagprotocol.com/exref/" + ls.get("acct")
      );
      setShorturl(shortify.link);
      const recentListDis = await levrows.map(function (k, i) {
        //return <li>{i} {k.percent}</li>;

        var id = "";
        console.log(ls.get("lev") + " " + i);
        if (parseInt(ls.get("lev")) === i + 1) {
          id = "current";
        }
        return (
          <>
            {i <= 4 && (
              <div class="col" id={id} key={i}>
                <div class="levels">
                  <label class="lvl">Rank {i + 1}</label>
                  <label class="percent">{k.percent / 100}%</label>
                  <span>
                    Target
                    <br />
                    {k.sales}
                    <br />
                    HTAGS
                  </span>
                </div>
              </div>
            )}
          </>
        );
      });

      setRecentData(recentListDis);
      const recentListDis2 = await levrows.map(function (k, i) {
        //return <li>{i} {k.percent}</li>;
        console.log(ls.get("lev") + " " + i);
        var id = "";
        if (parseInt(ls.get("lev")) === i + 1) {
          id = "currentLevel";
        }
        return (
          <>
            {i >= 5 && (
              <div class="col" id={id} key={i}>
                <div class="levels">
                  <label class="lvl">Rank {i + 1}</label>
                  <label class="percent">{k.percent / 100}%</label>
                  <span>
                    Target
                    <br />
                    {k.sales}
                    <br />
                    HTAGS
                  </span>
                </div>
              </div>
            )}
          </>
        );
      });
      setRecentData2(recentListDis2);
      setLoaded(true);
    }
  }
  async function checkRef() {
    var refAddress = ls.get("acct");
    //change this
    //var refAddress = "0x4d25ee203dc4ec06554617592d4098ef96cc00b1"
    if (refAddress) {
      const response = await fetch(validateUrl + refAddress);
      const jsonData = await response.json();

      console.log(jsonData.count);
      if (jsonData.count === 0) {
        setIsValid(0);
        return 0;
      } else {
        setIsValid(1);
        return 1;
      }
    } else {
      setIsValid(0);
    }
    return 1;
  }

  const wallet = useWallet();
  const [stateData, setStateData] = useState();

  return (
    <>
      <LoginCheck />

      <BackButton />

      <div class="app-content">
        <div id="open-modal" class="modal-window-qr">
          <a href="#" title="Close" class="modal-close">
            x
          </a>
          <div>
            <div style={{ color: "#0262D3", textAlign: "center" }}>
              Invitation Code
            </div>
            <QRCode
              value={
                "https://tagprotocol.com/exref/" + ls.get("acct") + "/home"
              }
              size="128"
            />
          </div>
        </div>

        <h3>Invite & Earn</h3>
        {isValid === 1 ? (
          <>
            <div class="invitationcode mb-3">
              <h4>Your Invitation Code</h4>
              <label>{ls.get("acct")}</label>
            </div>

            <div class="invitationcode mb-3">
              <h4>Your Invitation Link</h4>
              <a href={shorturl} target="_blank">
                {shorturl}
              </a>
            </div>

            <div class="buttonsec">
              <a href="#open-modal">Invite QR Code</a>
              <CopyToClipboard
                text={ls.get("acct")}
                onCopy={() => setStateData("Copied Address")}
              >
                <a class="refAction" href="javascript:void(0)">
                  Copy Code
                </a>
              </CopyToClipboard>
              <CopyToClipboard
                text={shorturl}
                onCopy={() => setStateData("Copied URL")}
              >
                <a class="refAction" href="javascript:void(0)">
                  Copy URL
                </a>
              </CopyToClipboard>
            </div>

            <div class="sharetext">
              Share Invite Code And Earn Commission Based On Slabs Below
            </div>

            {loaded === false ? (
              <div class="d-flex justify-content-between earnlevels mt-3">
                <div class="col">
                  {" "}
                  <Loader loadingText="Getting data from smart contract" />
                </div>
              </div>
            ) : (
              <>
                <div class="d-flex justify-content-between earnlevels mt-3">
                  {recentData}
                </div>
                <div class="d-flex justify-content-between earnlevels mt-3">
                  {recentData2}
                </div>
              </>
            )}

            <ul class="dl-lists" style={{ marginTop: "20px" }}>
              <li>
                <a
                  onClick={() => {
                    history.push("/trackRef");
                  }}
                >
                  TRACK INVITE EARNINGS
                </a>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div class="d-flex justify-content-between earnlevels mt-3">
              <div class="col">
                <p>
                  Invitation code not active. Please purchase at least one
                  hashtag to activate your invitation code.
                  <br />
                  <a
                    class="mt-4 btn innerBtn"
                    onClick={() => {
                      history.push("/Research");
                    }}
                  >
                    Register Hashtag
                  </a>{" "}
                </p>
              </div>
            </div>
          </>
        )}

        <div class="app-btnsec mt-4">
          <a href="/dashboard" class="btn">
            Back
          </a>
        </div>
      </div>
    </>
  );
}
export default Refearn;
