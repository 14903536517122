import React, { useState, useEffect } from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import BackButton from '../components/sharedBackButton';
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import InfiniteScroll from "react-infinite-scroll-component";
import { store } from 'react-notifications-component';



const Web3 = require('web3'); 
const web3 = new Web3(process.env.REACT_APP_RPC); 

function ClaimStat(){
    const wallet = useWallet()
    const blockNumber = wallet.getBlockNumber()
    const [stakeData,setStakeDate] = useState({});
    const [count, setCount] = useState(0);
    const [statex, setStatex] = useState({hasMore:true, items:[]});
    const [page,setPage] = useState(1);
    const [maxPage,setMaxPage] = useState()
    const [claimState,setClaimState] = useState(0)
    const [claimData,setClaimData] = useState([])
    const initialData = async() => {
      
    }
   
    let history = useHistory();
    
    useEffect(() => {
      loadData();
        initialData();
      }, []);
    async function loadData(){
      const ClaimFetch = await fetch(process.env.REACT_APP_LEDGER_API+"v1/summary/getClaims/"+ls.get('acct'));
      const ClaimRow = await ClaimFetch.json();
      const activeClaims = (ClaimRow['claims'])
      
      if(activeClaims>0){
        
        setClaimState(ClaimRow['data']['status'])
        setClaimData(ClaimRow['data'])
        console.log(ClaimRow['data'])
      }
    }

   
    
    return(
        <>
     
  
   <LoginCheck/>
   <BackButton/>
   <div class="app-content">
		<h3>Claim Status</h3>
        
    {claimState == 1 &&
    <ul class="ClaimStatus">
      <li class="claimStatus_Active"><div class="check"></div>Claim Accepted</li>
          <li class="claimStatus_Active"><div class="check"></div>Oracle Calculating Claim</li>
          <li class="claimStatus_inActive"><div class="incheck"></div>Claim Dispatched</li>
          <li class="claimStatus_inActive"><div class="incheck"></div>Claim Settled</li>
    </ul>
    }

    {claimState == -1 &&
        <ul class="ClaimStatus">
          <li class="claimStatus_Active"><div class="check"></div>Claim Accepted</li>
          <li class="claimStatus_Active"><div class="check"></div>Oracle Calculating Claim</li>
          <li class="claimStatus_Active"><div class="check"></div>Claim Dispatched</li>
          <li class="claimStatus_inActive"><div class="incheck"></div>Claim Settled</li>
        </ul>
        }

{claimState == 0 &&
        <ul class="ClaimStatus">
        <li class="claimStatus_Active"><div class="check"></div>Claim Accepted</li>
        <li class="claimStatus_Active"><div class="check"></div>Oracle Calculating Claim</li>
        <li class="claimStatus_Active"><div class="check"></div>Claim Dispatched</li>
        <li class="claimStatus_Active"><div class="check"></div>Claim Settled</li>
      </ul>
        }
        
       

        <div class="myhashtags mt-3">
        
		</div>

		<div class="app-btnsec">
			<a href="/account" class="btn mr-2">Back</a>
		</div>
	</div>
      
    
     

   

    </>
    );
}
export default ClaimStat;