import React from "react";
import { useHistory } from "react-router-dom";
import { useWallet, UseWalletProvider } from 'use-wallet';
import ls from 'local-storage'


function AppFooter(){
  
    let history = useHistory();
    const wallet = useWallet()
    
    
    function gobackApp(e){
        e.preventDefault();
        history.goBack();
        
    }
    function logout(){
        wallet.reset();
        ls.remove('addr')
        ls.remove('acct');
        ls.remove('claimed');
        ls.remove('walletconnect');
        ls.remove('unref');
        history.push('/wallet');
        
    }
    return(
        <>
             <div class=" footerApp"><button onClick={gobackApp} class="text-decoration-none">Back</button><button onClick={logout} class="text-decoration-none">Disconnect</button></div>
             
            


    </>
    );
}
export default AppFooter;