import React, { useState, useEffect } from "react";
import BackButton from "../components/sharedBackButton";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../affStyle.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
function formatNumber(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

const summaryUrl = process.env.REACT_APP_TREASURY_API+"v1/summary/";
function RefLanding(){
    const [summaryData, setSummaryData ] = useState({});
	const [summaryData1,setSummaryData1] = useState({});
    const [recentData1, setRecentData1] = useState([]);
    const [recentData2, setRecentData2] = useState([]);
    document.body.style.backgroundColor = "#fff"
	document.body.classList.add("newrefpage");
    useEffect(() => {
        getSummary();
      }, []);
    
      const getSummary = async () => {
          const response = await fetch(summaryUrl);
          const jsonData = await response.json();
          setSummaryData(jsonData);

		  const response1 = await fetch('https://ledger.tagdev.info/v1/summary/getHtagPriceCount')
		  const jsonData1 = await response1.json()
		  setSummaryData1(jsonData1)
          console.log(jsonData)

          const recentReg = await fetch(process.env.REACT_APP_TREASURY_API+"v1/summary/recents/12/0");
          const jsonRec = await recentReg.json();
          const recentListDis1 = await jsonRec.map(function(k,i){
            return <li class="recentHashPromo" key={i}>#{(k.hashtag)}</li>;
          });
          setRecentData1(recentListDis1);

          const recentSrch = await fetch(process.env.REACT_APP_TREASURY_API+"v1/summary/recentsearched/6");
          const jsonSrc = await recentSrch.json();
          const recentSearch = await jsonSrc.map(function(k,i){
            return <li class="recentHashPromo" key={i}>#{(k.hashtag).substr(0,9)}</li>;
          });
          setRecentData2(recentSearch)
      };
    return(
        <>

<header>
        <div class="container">
            <div class="row">
                <div class="col-md-8 d-flex align-items-center justify-content-between">
                    <a class="logo" href="index.html">
                        <img src="images/logo.png" class="img-fluid"/>
                    </a>
					
					<nav class="navbar navbar-expand-lg ">
					  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
					  </button>
						  <div class="collapse navbar-collapse" id="navbarNavDropdown">
							<ul class="navbar-nav">
							  <li class="nav-item">
								<a class="nav-link" href="http://tagcoin.io/">Tagcoin</a>								
							  </li>
							  <li class="nav-item">
								<a class="nav-link" href="https://tagprotocol.gitbook.io/tagprotocol/">Learn More</a>
								
							  </li>
							  <li class="nav-item">
								<a class="nav-link" href="https://github.com/tagprotocol/White-Paper" id="">White Paper</a>
							  </li>
							  <li class="nav-item">
								<a class="nav-link" href="https://tagprotocol.medium.com/" id="">
								  Blog
								</a>
							  </li>
							  <li class="nav-item">
								<a class="nav-link" href="mailto:info@tagprotocol.com" id="">
								  Contact
								</a>
							  </li>
							</ul>
						  </div>
						  
					</nav>				
					
                </div>
				
                <div class="col d-flex align-items-center justify-content-end hideonmobile">
					<a href="/recentHashtags" class="bluebtn btnsmall mr-3">Search Hashtag</a>
					<a href="/wallet" class="blackbtn btnsmall">Register Now</a>
                </div>
            </div>
        </div>
    </header>
	
<section class="topsection">
	<div class="container">
		
		<div class="row mt-4">
			<div class="col-md-6">
				<span>NFT - REGISTER - STAKE - EARN - TRADE</span>
				<h1>Collect Unique<br/> Social Media #Hashtags</h1> 
				<p>Stake them to earn tagcoins every hour based upon their performance.</p>
				<div class="btnsec">
					<a href="/wallet?ref=welcome" class="blackbtn btnlg mr-3">OPEN APP</a>
					<a href="#contactFtr" class="btnoutline btnlg">Ask A Question</a>
				</div>
			</div>
			<div class="col-md-6" data-aos="zoom-in">
					<div class="macslider">
						<img src="images/mac.png"/>
						<div class="slide">
							<div style={{backgroundImage:"url(images/slide-1.jpg)"}}></div>
							<div style={{backgroundImage:"url(images/slide-2.jpg)"}}></div>
							<div style={{backgroundImage:"url(images/slide-3.jpg)"}}></div>
							<div style={{backgroundImage:"url(images/slide-4.jpg)"}}></div>
							<div style={{backgroundImage:"url(images/slide-5.jpg)"}}></div>
							<div style={{backgroundImage:"url(images/slide-6.jpg)"}}></div>
						</div>
					</div>
			</div>
		</div>
	</div>
</section>

<section class="stats">
	<div class="container">
		<div class="row">
			<div class="col-md-3">
				<div class="statsin">
					<label>HASHTAG AVAILABLE</label>
					<span class="js-counter">100,000</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="statsin">
					<label>HASHTAG SOLD</label>
					<span>{formatNumber(parseInt(summaryData1.count))}</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="statsin">
					<label>CURRENT PRICE</label>
					<span>{formatNumber(parseInt(summaryData1.baseRate))}$</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="statsin">
					<label>NEXT PRICE</label>
					<span>{formatNumber(parseInt(summaryData1.baseRate)+50)}$</span>
					<label>*Increases by 50$ on sale of every 5,000 hashtags</label>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="about" id="about">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-7">
				<h2>ABOUT</h2>
				<p class="mt-5">Tagprotocol is a blockchain agnostic coin ecosystem that has a well defined production, mint & burn tokenomics and uses #hashtag based NFT tokens as it’s virtual mining machine rather than a physical mining machine to save on additional electricity consumption.</p>
				<div class="btnsec">
					<a href="/wallet" class="blackbtn btnlg mr-3">Register Now</a>
					<a href="#contactFtr" class="btnoutline btnlg">Ask A Question</a>
				</div>
			</div>
			<div class="col-md-5 text-right">
				<div class="cirlcespinner">
					<img src="images/nft.png"/>
					<img src="images/hash.png" class="hashimg"/>
					<div class="spinning-loader"></div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="contract" id="contract">
	<div class="container">
		<div class="row">
			<div class="col text-center">
				<h2>HASHTAG NFT CONTRACT</h2>
				<a href="https://bscscan.com/token/0x8d2252ce5b346127afcaf3f5472d12aa2c8a618e" target="_blank">0x8d2252ce5b346127afcaf3f5472d12aa2c8a618e </a>
			</div>
		</div>
	</div>
</section>

<section class="trending" id="hashtag">
	<div class="container">
		<div class="row">
			<div class="col-md-5" data-aos="fade-up">
				<img src="images/phone.png"/>
			</div>
			
			<div class="col-md-7">
				<h2>RECENTLY REGISTERED <br/>HASHTAGS</h2>
      
      <ul id="welcomeRR">{recentData1}</ul>
      
				
				<div class="col d-flex align-items-center justify-content-end mt-5 pr-5">
					<a href="/wallet" class="blackbtn btnsmall mr-3">Register Now</a>
					<a href="#contactFtr" class="bluebtn btnsmall">Ask A Question</a>
				</div>
			</div>
			
		</div>
	</div>
</section>

<section class="collectibles">
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<h2>Stop looking at NFT's as <span>dead collectibles</span></h2>
				<h3>HASHTAG NFT'S ARE ALIVE.</h3>
				<p>They generate tagcoins every hour for you based on their social media performance.</p>
				<p>Their daily earnings can be used to calculate yearly returns.  <br/>
				Yearly returns helps discover more efficient base price to trade hashtags. </p>
				
				<div class="tagcoin">
					<h4>TAGCOIN</h4>
					<p>Blockchain agnostic Store of value crypto token that has limited supply and needed to trade hashtags and participate in montly auctions.</p>
				</div>
				
			</div>
		</div>
	</div>
</section>

<section class="howitworks">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="text-white">How it works</h2>
			</div>
		</div>
		<div class="row stepsrow mt-5 justify-content-between">
			<div class="col-md-3 p-0 firststep" data-aos="fade-right">
				<div class="steps">
					<img src="images/nftsteps.png"/>
					<label>01</label>
				</div>
				<h4>Register Hashtag</h4>
				<p>Register unique social media hashtag and get it as an NFT on Binance Smart Chain.</p>
			</div>
			<div class="col-md-3 p-0 secondstep" data-aos="fade-right">
				<div class="steps">
					<img src="images/nftsteps-2.png"/>
					<label>02</label>
				</div>
				<h4>Stake your Hashtag</h4>
				<p>Stake your hastag and earn tagcoins every hour based upon its social media performance.</p>
			</div>
			<div class="col-md-3 p-0 thirdstep" data-aos="fade-right">
				<div class="steps">
					<img src="images/nftsteps-3.png"/>
					<label>03</label>
				</div>
				<h4>Trade Tagcoin</h4>
				<p>HODL Tagcoin or Trade them on the open market agianst other crypto currencies</p>
			</div>
			<div class="col-md-3 p-0 laststep" data-aos="fade-right">
				<div class="steps">
					<img src="images/nftsteps-4.png"/>
					<label>04</label>
				</div>
				<h4>Auction Hashtag</h4>
				<p>Trade Hashtags with other participants in an open auction market.</p>
			</div>
		</div>
	</div>
</section>

<section class="community">
	<div class="container">
			<h2 class="text-center">WHY BECOME PART OF <br/>TAGPROTOCOL COMMUNITY</h2>
		<div class="row mt-5">
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/tagcoin.png"/>
					<h4>Tagcoin</h4>
					<p>First store of value coin that can adopt any blockchain on community consensus.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/transparency.png"/>
					<h4>#Hashtag Ownership</h4>
					<p>Permanently own hashtags used by billions of people everyday.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/innovation.png"/>
					<h4>NFT Innovation</h4>
					<p>Worlds first NFT that discovers trade value based on performance.</p>
				</div>
			</div>
		</div>
		<div class="row mt-5 justify-content-center">
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/stability.png"/>
					<h4>NFT Mining</h4>
					<p>First protocol that turns NFT into a virtual mining machine.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/cashflow.png"/>
					<h4>Passive Earning</h4>
					<p>Generate tagcoin every hour based upon your hashtag performence.</p>
				</div>
			</div>	
			<div class="col-md-4">
				<div class="comcont">
					<img src="images/autonomy.png"/>
					<h4>Autonomy</h4>
					<p>Governance based protocol management. community makes all decisions.</p>
				</div>
			</div>				
		</div>
	</div>
</section>

<section class="parties p-0">
	<div class="container-fluid p-0">
		<div class="row m-0">
			<div class="col-md-6">
				<h2 class="text-white">DIGITAL AUTONOMOUS <br/>ORGANISATION</h2>
			</div>
			<div class="col-md-6">
				<div class="text-right partiescont">
					<h2 class="">NO THIRD PARTIES <br/>INVOLVED</h2>
					<p class="mb-4 mt-4">DAO or Decentralized Autonomous Organization is like a company that has no central leadership. All important decisions regarding the protocol are made by the participants themselves. Any participant can raise a proposal for change in some feature or value within the protocol and other participants can evaluate and vote on it.</p>
					<div class="btnsec justify-content-end">
						<a href="/wallet" class="blackbtn btnlg mr-3">Register Now</a>
						<a href="#contactFtr" class="btnoutline btnlg">Ask A Question</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="recregister">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-4">
				<h2 class="text-white">POPULAR HASHTAGS</h2>
			</div>
			<div class="col-md-8">
				<ul>
				<li>#season</li>
				<li>#zumba</li>
				<li>#lovefood</li>
				<li>#photograph</li>
				<li>#fathersday</li>
				<li>#valentine</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col d-flex align-items-center justify-content-end mt-3">
				<a href="/wallet" class="blackbtn btnsmall mr-3">Register Now</a>
				<a href="#contactFtr" class="bluebtn btnsmall">Ask A Question</a>
            </div>
		</div>
	</div>
</section>


<footer id="contactFtr">
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<a href="mailto:info@tagprotocol.com" class="btn">Contact Us</a>
				<p>Feel free to contact us with any question or inquiry. </p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-5">
				<small>Cryptocurrency & Blockchain projects are high risk operations because of their experimental nature. By participating in this operation, participants declare to understand and assume the following risks:</small> 
				<small><strong>The lack of regulation:</strong> the purchaser agrees not to benefit from any guarantees associated with presale on regulated financial markets or other regulated financial investments; </small>
				<small><strong>Capital loss: </strong> the purchaser accepts the risk of a total or partial capital loss in cryptocurrency or in the token; </small>
				<small><strong>Volatility or market risk: </strong> the value of tokens, just like that of cryptocurrencies in general, can be extremely volatile and subject to significant, and largely unforeseeable fluctuations. Moreover, the market or markets on which these tokens are traded do not offer the same guarantees that are generally applicable to conventional financial markets. </small>
			</div>
		</div>
		<div class="row mt-3 pb-4 align-items-center">
			<div class="col-md-6">
				<div class="copyright text-white">
					Copyright © 2021 Tagprotocol.com
				</div>
			</div>
			<div class="col-md-6">
				<div class="socialmedia text-white">
					<ul>
						<li><a href="#"><i class="fa fa-twitter"></i></a></li>
						<li><a href="#"><i class="fa fa-instagram"></i></a></li>
						<li><a href="#"><i class="fa fa-linkedin-square"></i></a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</footer>

	
	
	
	
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script> 

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.1/css/swiper.min.css"/>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.1/js/swiper.min.js"></script>

<link rel="stylesheet" href="https://unpkg.com/aos@2.3.1/dist/aos.css" />
<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>

    
    </>
    );
}
export default RefLanding;