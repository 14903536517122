import React from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import BackButton from "../components/sharedBackButton";
import ls from "local-storage";
import LoginCheck from "../components/LoginCheck"; //<LoginCheck/>
import Countdown from "react-countdown";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

function Grades() {
  const wallet = useWallet();
  const blockNumber = wallet.getBlockNumber();
  let history = useHistory();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completed />;
    } else {
      // Render a countdown
      return (
        <span>
          {days} DAYS {hours} HOURS {minutes} MINUTES
        </span>
      );
    }
  };
  const Completed = () => <span>Halving done</span>;
  return (
    <>
      <LoginCheck />
      <BackButton />
      <div class="app-content">
        <h3>GRADE PAYOUT CHART</h3>
        <span class="halving_counter">
          Mining halves in approx.
          <br />{" "}
          <Countdown
            date={new Date(Date.parse("08/21/2023 04:00:00 GMT+4"))}
            renderer={renderer}
          />
        </span>

        <ul class="accinfo-list">
          <li>
            <label>GRADE 1</label>
            <span>300 TAG</span>
          </li>
          <li>
            <label>GRADE 2</label>
            <span>290 TAG</span>
          </li>
          <li>
            <label>GRADE 3</label>
            <span>280 TAG</span>
          </li>
          <li>
            <label>GRADE 4</label>
            <span>270 TAG</span>
          </li>
          <li>
            <label>GRADE 5</label>
            <span>260 TAG</span>
          </li>
          <li>
            <label>GRADE 6</label>
            <span>250 TAG</span>
          </li>
          <li>
            <label>GRADE 7</label>
            <span>240 TAG</span>
          </li>
          <li>
            <label>GRADE 8</label>
            <span>230 TAG</span>
          </li>
          <li>
            <label>GRADE 9</label>
            <span>220 TAG</span>
          </li>
          <li>
            <label>GRADE 10</label>
            <span>210 TAG</span>
          </li>
          <li>
            <label>GRADE 11</label>
            <span>200 TAG</span>
          </li>
          <li>
            <label>GRADE 12</label>
            <span>190 TAG</span>
          </li>
          <li>
            <label>GRADE 13</label>
            <span>180 TAG</span>
          </li>
          <li>
            <label>GRADE 14</label>
            <span>170 TAG</span>
          </li>
          <li>
            <label>GRADE 15</label>
            <span>160 TAG</span>
          </li>
          <li>
            <label>GRADE 16</label>
            <span>150 TAG</span>
          </li>
          <li>
            <label>GRADE 17</label>
            <span>140 TAG</span>
          </li>
          <li>
            <label>GRADE 18</label>
            <span>130 TAG</span>
          </li>
          <li>
            <label>GRADE 19</label>
            <span>120 TAG</span>
          </li>
          <li>
            <label>GRADE 20</label>
            <span>110 TAG</span>
          </li>
          <li>
            <label>GRADE 21</label>
            <span>100 TAG</span>
          </li>
        </ul>

        <div class="app-btnsec">
          <a onClick={() => history.goBack()} class="btn mr-2">
            Back
          </a>
        </div>
      </div>
    </>
  );
}
export default Grades;
